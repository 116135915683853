import React from "react";
import { Icons } from "../../assets/constant";

const Work = () => {
  return (
    <div className="flex flex-col lg:grid lg:grid-cols-2 p-12 gap-8 shadow-md">
      <div className="flex justify-center items-center">
        <img
          src={Icons.L8}
          alt="Work-Life Balance"
          className="rounded-md object-center h-[300px]"
        />
      </div>
      <div className="flex flex-col justify-center">
        <h1 className="text-3xl font-bold mb-4 text-center lg:text-left">
          Work-Life Balance & Flexible Working Environment: Because Life Matters
        </h1>
        <p className="text-lg font-medium text-justify md:text-xl">
          We understand that a healthy work-life balance is essential to your
          well-being. At BPS, we offer flexible working hours and remote work
          options, ensuring that you can maintain a fulfilling personal life
          while thriving in your professional role. Our supportive environment
          allows you to be your most productive self, with the freedom to manage
          your time in a way that suits your individual needs.
        </p>
      </div>
    </div>
  );
};

export default Work;
