

import React from "react";
import { Icons } from "../../assets/constant";

const businessApproachData = [
  {
    title: "Flexible and Adaptive: Built to Evolve",
    description:
      "In a fast-paced world, flexibility is key. Our approach is designed to adapt to your changing needs, ensuring that we stay ahead of the curve in offering scalable solutions. From rapid pivots to long-term adjustments, we’re committed to evolving with your business for continuous success.",
  },
  {
    title: "Process-Driven: Streamlining Success",
    description:
      "Efficiency and effectiveness begin with process. Our methodical, well-defined processes ensure that every task is executed with precision, leaving no room for errors or delays. By standardizing and streamlining workflows, we deliver faster, more reliable outcomes, giving you the competitive edge.",
  },
];

const BusinessApproach = () => {
  return (
    <div className="flex flex-col lg:flex-row m-4 md:m-12 gap-5 bg-gray-50 shadow-lg rounded-md">
      <div className="w-full lg:w-1/2">
        <ul className="space-y-4">
          {businessApproachData.map((item, index) => (
            <li key={index} className="bg-white p-4 rounded-md shadow">
              <h3 className="text-lg md:text-xl font-semibold text-gray-700">
                {item.title}
              </h3>
              <p className="text-gray-600 mt-2 text-sm md:text-base">
                {item.description}
              </p>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex justify-center items-center w-full lg:w-1/2">
        <img
          src={Icons.I15}
          loading="lazy"
          alt="Business Approach"
          className="rounded-md w-full md:w-[662px] md:h-[348px] h-auto"
        />
      </div>
    </div>
  );
};

export default BusinessApproach;
