import React from "react";
import { Icons } from "../../assets/constant";

const BPS = () => {
  const content = [
    {
      heading: "WHY BPS?",
      heading1: "Why BPS? Excellence Delivered; Success Guaranteed!",
      image: Icons.B2,
    },
    {
      heading:
        "Extended Teams: The Advantage of Leveraging Expertise for Seamless Scalability",
      description:
        "At BPS, we offer the powerful advantage of an extended team model, enabling businesses to efficiently manage larger volumes of work while maintaining quality and operational flexibility. By leveraging offshore expertise, we provide scalable solutions that allow you to adapt quickly to changing demands without compromising on excellence. Our highly skilled professionals work seamlessly enhancing operational efficiency, driving cost savings, and ensuring access to specialized knowledge. We guarantee faster turnaround times, same-day project deliverables for our clients. At BPS, we empower your business with the right talent and resources, ensuring your success is built on a foundation of innovation and scalability.",
      image: Icons.B4,
    },
    {
      heading:
        "Improved Margins: Maximizing Profitability Through Strategic Outsourcing",
      description:
        "At BPS, we specialize in driving better margins on engagements by capitalizing on cost advantages offered by favourable labour market conditions. By offshoring routine, time-consuming tasks, businesses can significantly reduce operational costs and optimize resource allocation, allowing for a more cost-effective approach to operations.",
      description1:
        "This strategy not only lowers overhead but also frees up valuable time and energy, enabling organizations to focus on their core capabilities, strategic growth, and expanding their client base. With the ability to scale operations efficiently, businesses can better navigate competitive landscapes, ensuring they stay ahead while improving their profitability.",
      description2:
        "Our approach empowers businesses to achieve sustainable growth by improving margins, enhancing productivity, and driving innovation without the added financial strain of maintaining in-house teams for routine functions. At BPS, we help you unlock the full potential of outsourcing, ensuring you stay competitive and achieve your financial goals with ease.",
      image: Icons.B3,
    },
    {
      heading:
        "Larger Window: Round-the-Clock Availability for Enhanced Client Service",
      description:
        "Outsourcing offers the invaluable advantage of round-the-clock availability, driven by time zone differences, which enables businesses to provide continuous support and service to clients across the globe. By tapping into global talent pools, companies can ensure that work is being carried out 24/7, enhancing responsiveness and improving overall client satisfaction.",
      description1:
        "This extended availability allows businesses to handle larger volumes of work efficiently, ensuring that critical tasks are completed without delays. It also facilitates quicker resolution of client inquiries and faster project turnarounds, reinforcing a commitment to excellence in service delivery.",
      description2:
        "With outsourcing, businesses can scale operations seamlessly, ensuring they meet the demands of clients regardless of time zone constraints. At BPS, we understand the importance of timely, high-quality service, and our round-the-clock operations guarantee that your business is always equipped to provide exceptional client support, driving success and strengthening long-term relationships.",
      image: Icons.B6,
    },
    {
      heading:
        "Professional Experience: Expertise Driven by Advanced Credentials and Proven Excellence",
      description:
        "At BPS, we pride ourselves on the exceptional professional experience of our team. The majority of our members hold advanced qualifications such as MBA and CPA equivalents, coupled with extensive experience in financial diligence, accounting, and other critical business functions. This deep expertise enables us to deliver the highest standard of service, tailored to meet the unique needs of each client.",
      description1:
        "Our team members bring a wealth of knowledge from working with diverse teams across multiple geographies, allowing us to offer a global perspective and an adaptive approach to problem-solving. Many of our professionals have gained valuable onshore experience, providing them with a nuanced understanding of client needs and expectations, making them highly proficient in navigating complex, cross-border business environments.",
      image: Icons.B5,
    },
    {
      heading: "Trust: The Cornerstone of Our Client Relationships",
      description:
        "At BPS, we understand that trust is the most vital element of any successful business relationship. For over 7 years, we have consistently delivered reliable, high-quality services that have fostered lasting partnerships with our clients. Our experience has shown us that trust is not simply earned—it's built through a track record of integrity, transparency, and consistent excellence.",
      description1:
        "Trust is at the heart of everything we do. We are committed to upholding the highest standards of professionalism, ensuring that our clients always feel confident in our ability to deliver results. By prioritizing open communication, ethical practices, and a dedication to client success, we create strong, long-term relationships that go beyond transactional engagements.",
      image: Icons.B1,
    },
  ];

  return (
    <div className="flex flex-col gap-12 bg-slate-100 p-4 md:p-12">
      {content.map((content, index) => (
        <div
          key={index}
          className={`w-full flex flex-col-reverse lg:flex ${
            index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
          } items-center`}
        >
          <div className="w-full lg:w-1/2 px-2 md:px-6">
            <h1 className="text-2xl md:text-3xl font-semibold mb-4">
              {content.heading}
            </h1>
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              {content.heading1}
            </h2>
            <p className="text-base md:text-lg mb-4 xs:mb-2 text-justify">
              {content.description}
            </p>
            {content.description1 && (
              <p className="text-base md:text-lg mb-4 xs:mb-2 text-justify">
                {content.description1}
              </p>
            )}
            {content.description2 && (
              <p className="text-base md:text-lg mb-4 xs:mb-2 text-justify">
                {content.description2}
              </p>
            )}
          </div>
          <div className="w-full lg:w-1/2 flex justify-center p-4">
            <img
              src={content.image}
              alt={content.heading}
              className="rounded-md max-w-full h-auto"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default BPS;
