import React from "react";
import { Icons } from "../../assets/constant";

const FinancialDueDiligence = () => {
  const services = [
    {
      title: "Financial Due Diligence (Quality of Earnings)",
      description: `We tailor our services to meet the unique needs of each transaction, assisting our partners in evaluating the financial health and operations of the target company. Our goal is to assess historical and projected performance, uncover underlying risks, and provide insights to maximize transaction value. We also support our onshore partners to carry out these analyses when they are working on sell-side transactions. We help our teams in preparing these analyses in the form of different schedules for the databook.`,
    },
    {
      title: "Quality Checks",
      description: `We support onshore teams by conducting rigorous quality checks on data books and final deliverables, ensuring the accuracy of PowerPoint presentations, Excel sheets, and PDF reports. Our attention to detail guarantees the highest standards of quality in every document.`,
    },
    {
      title: "Business Plan Assistance",
      description: `Understanding the unique needs of your company and its stakeholders, we assist in the development, review, and analysis of business plans, operational strategies, product/service lines, and financial projections. Our team also supports capital structuring, cost-revenue analysis, and even connects you with potential investors and funding sources.`,
    },
    {
      title: "Report/Content Writing & Quality Checks",
      description: `We help draft preliminary sections of due diligence reports and ensure they are updated and aligned with client expectations. Our team of skilled writers and analysts ensures all content is well-structured and error-free, contributing to seamless report preparation.`,
    },
  ];

  return (
    <div className="mt-10 md:pt-20 py-9 px-5 xs:px-9 sm:px-12 ">
      <h1 className="text-xl md:text-2xl font-bold text-start mb-6">
        Financial Due Diligence: Comprehensive Support for Informed
        Decision-Making
      </h1>

      <div className="flex flex-col md:flex-row items-start justify-between mb-8 gap-3">
        <div className="w-full md:w-1/2 mb-4 md:mb-0 ">
          <p className="mt-4 text-sm sm:text-base md:text-lg">
            At BPS, we provide critical backend support to our onshore partners
            in performing Financial Due Diligence, Financial Analysis, and
            Transaction Advisory Services. Acting as an extended team, we work
            directly with partners on live deals, assisting with every aspect of
            due diligence — from initial data compilation to the final review
            and completion of client reports. Our highly skilled and experienced
            team, with expertise honed at Big 4 firms, is proficient in
            utilizing advanced tools and performing detailed financial analysis.
            Our young, dynamic professionals are driven by a passion for
            learning and delivering results in complex, high-pressure
            environments.
          </p>
        </div>
        <div className="">
          <img
            src={Icons.I1}
            alt="Financial Due Diligence"
            className="rounded-lg shadow-lg w-[500px] h-[200px] float-right"
          />
        </div>
      </div>
      <h1 className="text-base sm:text-xl font-semibold py-5">
        Our Financial Due Diligence services include:
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {services.map((service, index) => (
          <div key={index} className="border p-6 rounded-lg shadow-lg">
            <h2 className="text-base sm:text-xl font-semibold mb-4">
              {service.title}
            </h2>
            <p className="text-sm sm:text-base md:text-lg">
              {service.description}
            </p>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <p className="text-sm sm:text-base md:text-lg">
          At BPS, we provide the expertise, precision, and support needed to
          facilitate smooth and successful financial due diligence processes.
          Our commitment to quality ensures your transactions are built on a
          solid foundation of insights and trust.
        </p>
      </div>
    </div>
  );
};

export default FinancialDueDiligence;
