import React from 'react'
import Financial from './Financial';
import Integrity from './Integrity';
import Restructuring from './Restructuring';
import Taxation from './Taxation';
import Empowering from './Empowering';
import Book from './Bookkeeping';
import Strategy from './Strategic';
import Analytics from './Analytics';
import Modeling from './Modeling';
import Tailored from './Tailored';

const AllServices = () => {
  return (
    <div>
        <Financial />
            <Integrity />
            <Empowering />
            <Book />
            <Strategy />
            <Analytics />
            <Modeling />
            <Tailored />
            <Restructuring />
            <Taxation />
    </div>
  )
}

export default AllServices;