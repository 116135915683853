

import React from "react";
import { Icons } from "../../assets/constant";

const Diversity = () => {
  return (
    <div className="flex flex-col lg:grid lg:grid-cols-2 p-12 gap-8 shadow-md">
      <div className="flex justify-center items-center">
        <img
          src={Icons.L10}
          alt="Diversity and Inclusion"
          className="rounded-md object-center h-[330px]"
        />
      </div>
      <div className="flex flex-col justify-center">
        <h1 className="text-3xl font-bold mb-4 text-center lg:text-left">
          Diversity, Equity, and Inclusion (DEI) at the Workplace: Celebrating
          Every Individual
        </h1>
        <p className="text-lg font-medium text-justify md:text-xl">
          Diversity is our strength, and inclusivity is our promise. At BPS, we
          are committed to creating a workplace where everyone feels respected,
          valued, and heard. Our focus on DEI ensures that every individual,
          regardless of background or identity, is given equal opportunities to
          succeed. We celebrate differences and cultivate an environment that
          fosters mutual respect, understanding, and belonging for all our
          employees.
        </p>
      </div>
    </div>
  );
};

export default Diversity;
