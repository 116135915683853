import React, { useState } from "react";
import { Icons } from "../../assets/constant";

const Discover = () => {
  const [showFullText, setShowFullText] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5">
      <div className="w-full md:w-1/2 flex justify-center">
        <img
          src={Icons.A6}
          loading="lazy"
          alt="Discover"
          className="rounded-lg w-[620px] h-[300px]"
        />
      </div>

      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-3">
          Who We Are: Your Trusted Partner
        </h1>
        <p className="text-sm lg:text-xl leading-relaxed">
          Founded in <strong>early 2018</strong> by seasoned professionals with
          deep expertise in the professional services industry, BPS specializes
          in Finance & Accounting, Financial Due Diligence, Data Analytics, and
          business support solutions. With over 50+ years of collective
          experience of our management team working with Big 4 firms and Fortune
          500 companies, we deliver excellence across the globe.
        </p>

        {showFullText && (
          <>
            <p className="text-sm lg:text-xl leading-relaxed mt-4">
              Our vision is to be your trusted business partner, providing
              end-to-end support that drives growth, operational efficiency, and
              scalability. We are committed to forging long-term relationships
              that empower your success—acting as an extension of your team,
              invested in your goals.
            </p>
            <p className="text-sm lg:text-xl leading-relaxed mt-4">
              At BPS, we uphold values of{" "}
              <strong>integrity, respect, and employee well-being.</strong> We
              are customer-centric, employee-friendly, and socially responsible.
              Certified{" "}
              <strong>under ISO 27001:2022 for Information Security</strong>, we
              operate with the highest standards of security and quality.
              Headquartered in New Delhi, we are operating from various parts of
              India and serve clients worldwide, with a strong presence in the{" "}
              <strong>US, UK, and Canada.</strong>
            </p>
            <p className="text-sm lg:text-xl leading-relaxed mt-4">
              We are a <strong>dynamic team of 70+ professionals,</strong>{" "}
              dedicated to delivering impactful solutions that ensure success
              for all stakeholders.
            </p>
            <p className="text-sm lg:text-xl leading-relaxed mt-4">
              This is the additional information that will appear when the user
              clicks "Read More". We aim to expand our reach further and
              continue improving our services to provide unmatched value to our
              clients worldwide.
            </p>
          </>
        )}

        <button
          onClick={handleReadMoreClick}
          aria-expanded={showFullText}
          className="font-bold w-36 h-10 text-white bg-yellow-500 hover:bg-yellow-600 rounded-full flex justify-center items-center gap-2 mt-4"
        >
          {showFullText ? "Read Less" : "Read More"}
        </button>
      </div>
    </div>
  );
};

export default Discover;
