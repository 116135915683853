

import React from "react";
import { Icons } from "../../assets/constant";

const Vision = () => {
  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5">
      <div className="w-full md:w-1/2">
        <img
          src={Icons.A3}
          loading="lazy"
          alt="Our Vision"
          className="w-full h-[300px] object-cover rounded-md"
        />
      </div>

      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-3">Our Vision</h1>
        <p className="text-sm lg:text-xl">
          Looking ahead, BPS is committed to continuing its path of growth,
          driven by innovation and a deep commitment to client and employee
          satisfaction. As we expand our reach and services, we will continue to
          remain agile, ensuring that we adapt to new market demands while
          staying true to our values of trust, excellence, and integrity. We aim
          to be at the forefront of the advisory industry, setting the standard
          for service excellence, client relationships, and organizational
          culture.
        </p>
      </div>
    </div>
  );
};

export default Vision;
