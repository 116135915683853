

import React from "react";
import { Icons } from "../../assets/constant";

const Benefits = () => {
  return (
    <div className="flex flex-col lg:grid lg:grid-cols-2 p-12 gap-8 shadow-md">
      <div className="flex justify-center items-center">
        <img
          src={Icons.L9}
          loading="lazy"
          alt="Medical Benefits"
          className="rounded-md object-center h-[280px]"
        />
      </div>
      <div className="flex flex-col justify-center">
        <h1 className="text-3xl font-bold mb-4 text-center lg:text-left">
          Medical Benefits: Your Health, Our Priority
        </h1>
        <p className="text-lg font-medium text-justify md:text-xl">
          At BPS, we value the health and well-being of our employees. We offer
          medical insurance coverage to ensure you, and your loved ones are
          taken care of. Our medical benefits package provides you with peace of
          mind, knowing that we've got your back when it comes to your health.
        </p>
      </div>
    </div>
  );
};

export default Benefits;
