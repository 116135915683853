import React, { useState } from "react";
import { Icons } from "../../assets/constant";
import { MdArrowOutward } from "react-icons/md";

const Analytics = () => {
  const [isReadMore, setIsReadMore] = useState(false);

  return (
    <div className="flex flex-col md:flex-row mt-10 md:pt-20 py-9 px-5 xs:px-9 sm:px-12">
      <div>
        <div className="hidden sm:block">
          <img
            src={Icons.I9}
            alt=""
            className="rounded-md w-[300px] lg:w-[500px] h-[200px] float-right ml-5"
          />
        </div>
        <h1 className="text-xl md:text-2xl font-bold py-3">
          Data Analytics: Empowering Informed Decision-Making{" "}
        </h1>
        <p className="py-3 text-sm sm:text-base md:text-lg">
          At BPS, we harness the power of data to drive strategic insights and
          fuel informed decision-making. Our Data Analytics services transform
          voluminous and complex datasets into actionable intelligence, helping
          businesses uncover hidden opportunities, mitigate risks, and optimize
          performance.
        </p>
        <p className="py-3 text-sm sm:text-base md:text-lg">
          Leveraging advanced analytical tools like Power Pivots, Power BI,
          Alteryx, we provide in-depth analysis tailored to your specific
          business needs. From financial forecasting to operational efficiency
          improvements, our solutions enable you to make data-driven decisions
          that enhance growth and profitability.
        </p>

        {isReadMore && (
          <p className="py-3 text-sm sm:text-base md:text-lg">
            With our expertise in data management, visualization, data-driven
            insights, BPS ensures that your organization stays ahead of the
            curve in today's data-centric business landscape. Let us help you
            unlock the full potential of your data, delivering insights that
            drive real, measurable results.
          </p>
        )}
        <button
          onClick={() => setIsReadMore(!isReadMore)}
          className="font-bold w-32 h-10 text-white bg-primaryYellow rounded-full flex justify-center items-center gap-2 mt-2"
        >
          {isReadMore ? (
            "Read Less"
          ) : (
            <>
              Read More <MdArrowOutward />
            </>
          )}
        </button>
      </div>
      <div className="block sm:hidden mt-5">
        <img
          src={Icons.I9}
          alt=""
          className="rounded-md w-full h-[200px] float-right ml-5"
        />
      </div>
    </div>
  );
};

export default Analytics;
