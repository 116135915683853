import React from "react";
import { Icons } from "../../assets/constant";
import { MdArrowOutward } from "react-icons/md";
import { Link } from "react-router-dom";

const Satisfied = () => {
  return (
    <div className="flex flex-col md:flex-row p-4 md:p-12 gap-5 bg-slate-100">
      <div className="w-full md:w-1/2 md:text-left">
        <h1 className="text-2xl sm:text-3xl font-bold py-3">
          Hear From Our Satisfied Clients: Real Results, Real Impact
        </h1>
        <p className="text-base sm:text-lg md:text-xl py-3">
          Explore how we've helped businesses across various industries enhance
          efficiency, reduce costs, and achieve their goals. Our client stories
          reflect our commitment to excellence, integrity, and long-term
          success. Discover the tangible impact of working with a trusted
          partner that understands your challenges and works relentlessly to
          drive results.
        </p>
        <Link to="/ourclient">
          <button className="font-bold w-32 h-10 text-white bg-yellow-500 hover:bg-yellow-600 rounded-full flex justify-center items-center gap-2">
            Read More <MdArrowOutward />
          </button>
        </Link>
      </div>

      <div className="w-full md:w-1/2">
        <img
          src={Icons.I13}
          loading="lazy"
          alt="Satisfied Clients"
          className="rounded-md w-full md:w-[662px] md:h-[369px] h-auto"
        />
      </div>
    </div>
  );
};

export default Satisfied;
