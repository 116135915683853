import React from "react";
import { Icons } from "../../assets/constant";

const Taxation = () => {
  return (
    <div className="flex flex-col md:flex-row mt-10 md:pt-20 py-9 px-5 xs:px-9 sm:px-12">
      <div>
        <div className="hidden sm:block">
          <img
            src={Icons.I5}
            alt=""
            className="rounded-md w-[300px] lg:w-[500px] h-[200px] float-right ml-5"
          />
        </div>

        <h1 className="text-xl md:text-2xl font-bold py-3">
          U.S. Taxation Backend Support Services: Strategic Solutions for
        </h1>
        <p className="py-3 text-sm sm:text-base md:text-lg">
          At BPS, we provide comprehensive U.S. Taxation Backend Support
          Services, ensuring that your business stays compliant and optimized
          within the complex U.S. tax landscape. Our tailored solutions are
          designed to optimize tax strategies, enhance compliance, and minimize
          liabilities, ensuring long-term financial success for your business.{" "}
        </p>

        <p className="py-3 text-sm sm:text-base md:text-lg">
          With BPS as your trusted tax advisor, you gain access to specialized
          expertise that navigates the complexities of U.S. taxation, drives
          strategic tax planning, and protects your business from potential
          risks. Let us help you unlock tax efficiencies and drive success in a
          dynamic global environment.
        </p>
      </div>
      <div className="block sm:hidden mt-5">
        <img
          src={Icons.I5}
          alt=""
          className="rounded-md w-full h-[200px] float-right ml-5"
        />
      </div>
    </div>
  );
};

export default Taxation;
