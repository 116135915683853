

import React from "react";
import { Icons } from "../../assets/constant";

const Focus = () => {
  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 justify-start gap-8">
      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-3">
          Client-Centric Focus
        </h1>
        <p className="text-sm lg:text-xl leading-relaxed">
          At BPS, our clients are at the heart of everything we do. Our
          commitment to providing personalized, high-impact advisory services
          ensures that every client's unique needs are met with tailored
          solutions. We prioritize building long-lasting relationships based on
          trust, integrity, and results. This client-first approach has earned
          us the reputation of being a trusted advisor and a valued partner
          across industries.
        </p>
      </div>

      <div className="w-full md:w-1/2">
        <img
          src={Icons.A10}
          alt="Client-Centric Focus"
          loading="lazy"
          className="w-full h-[300px] object-cover rounded-md"
        />
      </div>
    </div>
  );
};

export default Focus;
