import React from "react";
import { Icons } from "../../assets/constant";

const Restructuring = () => {
  return (
    <div className="flex flex-col md:flex-row mt-10 md:mt-20 py-9 px-5 xs:px-9 sm:px-12">
      <div>
        <div className="hidden sm:block">
          <img
            src={Icons.I2}
            alt=""
            className="rounded-md w-[300px] lg:w-[500px] h-[200px] float-right ml-5"
          />
        </div>
        <h1 className="text-xl md:text-2xl font-bold py-3">
          Restructuring Support: Navigating Change with Strategic Expertise{" "}
        </h1>
        <p className="py-3  text-sm sm:text-base md:text-lg">
          At BPS, we offer expert Restructuring Support services designed to
          help businesses navigate complex financial challenges and emerge
          stronger. Our tailored solutions focus on driving operational
          efficiency, mitigating financial risks, and ensuring long-term
          sustainability.
        </p>

        <p className="py-3  text-sm sm:text-base md:text-lg">
          With BPS by your side, you gain the expertise and support needed to
          implement effective restructuring strategies that protect your
          business and position it for future success. Let us help you drive
          sustainable growth, minimize financial risks, and navigate times of
          change with confidence.
        </p>
      </div>
      <div className="block sm:hidden mt-5">
        <img
          src={Icons.I2}
          alt=""
          className="rounded-md w-full h-[200px] float-right ml-5"
        />
      </div>
    </div>
  );
};

export default Restructuring;
