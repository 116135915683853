

import React from "react";
import { Icons } from "../../assets/constant";


const LifeAtBPS = () => {
  return (
    <div className="flex flex-col items-center px-4 py-8 pt-28">
      <h1 className="text-3xl font-bold mb-8 text-center">Life at BPS</h1>

      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center max-w-6xl gap-8">
        <div className="flex-1 text-lg leading-relaxed">
          <p>
            At BPS, we believe that a truly exceptional workplace goes beyond
            just the work itself. It’s about creating a culture that fosters
            growth, celebrates individuality, and values collaboration. Life at
            BPS is dynamic, inclusive, and designed to inspire you to bring your
            best self to work every day. From exciting team activities to
            personalized career development, we prioritize our employees’
            happiness, success, and well-being.
          </p>
        </div>

        
        <div className="flex-1">
          <img
            src={Icons.L1}
            alt="Team Collaboration"
            className="rounded-lg shadow-md w-[612px] h-[340px]"
          />
        </div>
      </div>
    </div>
  );
};

export default LifeAtBPS;
