import React from "react";
import { Icons } from "../../assets/constant";

const Rewards = () => {
  return (
    <div className="flex flex-col items-center px-4 py-8 pt-28">
      <div className="text-center max-w-4xl">
        <h1 className="text-3xl font-bold mb-4">
          Rewards and Recognition: Where Every Achievement Shines!
        </h1>
        <p className="py-5 px-4 text-lg font-medium">
          At BPS, we believe that every moment of excellence deserves the
          spotlight. That's why we make it a priority to reward and recognize
          the outstanding contributions of our team members! From recognising
          stars to team triumphs, we celebrate every step forward, fuelling a
          culture of motivation, creativity, and growth. Together, we grow, we
          inspire, and we create an atmosphere where excellence is always
          recognized. Let's keep the momentum going—because when one succeeds,
          we all rise!
        </p>
      </div>

      <div className="grid justify-center items-center mt-8">
        <img
          src={Icons.L7}
          alt="Rewards"
          className="w-full  rounded-lg shadow-md"
        />
      </div>
    </div>
  );
};

export default Rewards;
