import React, { useState } from "react";
import { Icons } from "../../assets/constant";

const Corporate = () => {
  const [showFullText, setShowFullText] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5 justify-start">
      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-3">
          Corporate Social Responsibility
        </h1>
        <p className="text-sm lg:text-xl">
          At BPS, we believe in the power of giving back to society and making a
          positive impact. Our{" "}
          <strong>Corporate Social Responsibility (CSR)</strong> initiatives are
          at the heart of our values, driving us to support causes that promote
          education, sustainability, and social equity.
        </p>
        <p className="text-sm lg:text-xl">
          We are committed to creating a brighter future by actively engaging in
          social practices that foster growth and uplift those in need. Whether
          it's through community outreach, educational support, or environmental
          sustainability programs, we strive to make meaningful contributions
          that go beyond business.
        </p>

        {showFullText && (
          <>
            <p className="text-sm lg:text-xl">
              Our team is passionate about making a difference, and we take
              pride in empowering individuals and communities to thrive. By
              aligning our efforts with the needs of society, we not only
              contribute to the well-being of others but also enrich our own
              corporate culture, fostering a sense of responsibility, respect,
              and purpose among our employees.
            </p>
            <p className="text-sm lg:text-xl">
              At BPS, we are not just building business success—we are building
              a legacy of positive change. Through our CSR initiatives, we
              continue to create lasting impacts that benefit all stakeholders,
              ensuring that our growth is shared with the world around us.
            </p>
          </>
        )}

        <button
          onClick={handleReadMoreClick}
          className="font-bold w-32 h-10 text-white bg-yellow-500 hover:bg-yellow-600  rounded-full flex justify-center items-center gap-2 mt-2"
        >
          {showFullText ? "Read Less" : "Read More"}
        </button>
      </div>

      <div className="w-full md:w-1/2">
        <img
          src={Icons.A4}
          loading="lazy"
          alt="Corporate Social Responsibility"
          className="w-full md:w-[620px] md:h-[300px] h-auto rounded-md"
        />
      </div>
    </div>
  );
};

export default Corporate;
