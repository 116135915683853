import React, { useState } from "react";
import { Icons } from "../../assets/constant";

const Outsourcing = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleReadMoreClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const Data = [
    {
      heading:
        "Why Financial Outsourcing? Outsource Financial Excellence for Seamless Success",
      description:
        "In today's fast-paced business environment, companies are under increasing pressure to maintain competitive advantages while controlling costs. Financial outsourcing has emerged as a key strategy for achieving both goals. By leveraging external expertise for critical financial services businesses can optimize efficiency, reduce overhead, and scale operations effectively.",
      image: Icons.F1,
    },
    {
      heading: "Cost Savings Through Financial Outsourcing",
      description:
        "One of the primary reasons companies, especially in the United States, turn to financial outsourcing is the potential for significant cost savings. Outsourcing financial functions allows businesses to tap into global talent pools and benefit from lower labour costs, especially when outsourcing to countries with lower wages but high expertise.",
      description1:
        "The Grant Thornton International Business Report (IBR) surveyed around 3,300 senior executives globally. It revealed that 40% of worldwide businesses are considering outsourcing one or more business operations.",
      description2:
        "In North America, 70% mention lowering costs, and 69% mention increasing efficiency; in Europe, 63% and 67%, respectively.",
      image: Icons.F3,
    },
    {
      heading: "Access to Expertise and Efficiency",
      description:
        "Outsourcing financial functions provides businesses with access to highly skilled professionals without the need to recruit and retain them in-house. Financial outsourcing firms employ experts in various areas—ensuring that your company benefits from high-quality services tailored to your needs.",
      description1:
        "Outsourcing also enhances efficiency. By entrusting non-core financial tasks to experts, businesses can focus on their core activities and strategic goals, knowing that their financial operations are in good hands. According to McKinsey & Company, 75% of businesses report improved process efficiency and productivity as a direct result of outsourcing key functions like accounting and finance.",
      image: Icons.F2,
    },
    {
      heading: "Scalability and Flexibility",
      description:
        "As your business grows, so do your financial needs. Outsourcing provides unparalleled flexibility, allowing companies to scale their financial operations quickly and efficiently without the burden of hiring additional full-time staff or investing in new infrastructure. By working with an outsourced partner, businesses can adapt to changing market conditions, seasonal variations, or business expansion without disruption.",
      image: Icons.F5,
    },
    {
      heading: "The Outsourcing Industry's Growth",
      description:
        "The global outsourcing market continues to grow, and the financial services sector is one of the largest contributors to this expansion.",
      description1:
        "According to SNS Insider Research, the global financial and accounting business process outsourcing (F&A BPO) market was valued at $60.93 billion in 2023.",
      description2:
        "The benefits of financial outsourcing are clear: cost savings, access to expertise, scalability, efficiency, and improved compliance.",
      description3:
        "As the outsourcing industry continues to grow, it's no wonder that more and more companies are turning to outsourced financial services to unlock new levels of performance and efficiency.",
      image: Icons.F4,
    },
  ];

  return (
    <div className="flex lg:p-24 xs:p-6 flex-col gap-12 bg-slate-100 s:pt-16">
      {Data.map((data, index) => (
        <div
          key={index}
          className={`w-full flex flex-col-reverse ${
            index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
          } items-center`}
        >
          <div className="w-full lg:w-1/2 px-2 md:px-6">
            <h1 className="md:text-3xl text-xl font-bold mb-4">
              {data.heading}
            </h1>
            <p className="md:text-lg text-base mb-4 text-justify">
              {data.description}
            </p>
            {index === 1 && (
              <>
                <p className="md:text-lg text-base mb-4 text-justify">
                  {data.description1}
                </p>
                {expandedIndex === index && (
                  <p className="md:text-lg text-base mb-4 text-justify">
                    {data.description2}
                  </p>
                )}
                <button
                  onClick={() => handleReadMoreClick(index)}
                  className="text-blue-500 underline mt-2"
                >
                  {expandedIndex === index ? "Read Less" : "Read More"}
                </button>
              </>
            )}
            {index === 2 && (
              <p className="md:text-lg text-base mb-4 text-justify">
                {data.description1}
              </p>
            )}
            {index === 4 && (
              <>
                <p className="md:text-lg text-base mb-4 text-justify">
                  {data.description1}
                </p>
                {expandedIndex === index && (
                  <>
                    <p className="md:text-lg text-base mb-4 text-justify">
                      {data.description2}
                    </p>
                    <p className="md:text-lg text-base mb-4 text-justify">
                      {data.description3}
                    </p>
                  </>
                )}
                <button
                  onClick={() => handleReadMoreClick(index)}
                  className="text-blue-500 underline mt-2"
                >
                  {expandedIndex === index ? "Read Less" : "Read More"}
                </button>
              </>
            )}
          </div>
          <div className="w-full lg:w-1/2 flex justify-center p-4">
            <img
              src={data.image}
              alt={data.heading}
              className="rounded-md max-w-full h-[300px]"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Outsourcing;
