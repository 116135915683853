import React from "react";
import { Icons } from "../../assets/constant";
import { MdArrowOutward } from "react-icons/md";
import { Link } from "react-router-dom";

const Outsourcing = () => {
  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5">
      <div className="w-full md:w-1/2">
        <img
          src={Icons.I11}
          alt="Life at BPS"
          loading="lazy"
          className="rounded-md w-full md:w-[662px] md:h-[369px] h-auto"
        />
      </div>

      <div className="w-full md:w-1/2 md:text-left">
        <h1 className="text-2xl sm:text-3xl font-bold py-3">Life at BPS –</h1>
        <h2 className="text-xl sm:text-2xl font-medium py-3">
          Experience the Energy of a Dynamic, Growth-Focused Team at BPS
        </h2>
        <p className="text-base sm:text-lg md:text-xl py-3">
          Life at BPS is dynamic, inclusive, and designed to inspire you to
          bring your best self to work every day. From exciting team activities
          to personalized career development, we prioritize our employees'
          happiness, success, and well-being.
        </p>
        <Link to="/lifeatbps">
          <button className="font-bold w-32 h-10 text-white bg-yellow-500 hover:bg-yellow-600 rounded-full flex justify-center items-center gap-2">
            Read More <MdArrowOutward />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Outsourcing;
