import React, { useState } from "react";
import { Icons } from "../../assets/constant";

const OurMission = () => {
  const [showFullText, setShowFullText] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center p-8 lg:p-12 gap-8 items-center">
      <div className="w-full lg:w-1/2">
        <h1 className="text-2xl md:text-3xl font-bold mb-6">
          Our Mission: Empowering Success Through Partnership & Innovation
        </h1>
        <p className="text-lg mb-4">
          At BPS, our mission is clear: to deliver world-class business support
          solutions that drive success, foster enduring relationships, and
          create lasting value. We are dedicated to working in close
          collaboration with our clients, focusing on continuous improvement and
          delivering excellence in every engagement.
        </p>
        <p className="text-lg mb-4">
          Client satisfaction is at the core of everything we do. Every decision
          and action we take is centered on providing the best possible outcomes
          for our clients. We go above and beyond to ensure that their needs are
          met and that we remain a trusted partner throughout their journey. At
          BPS, we don't just aim to be a service provider; we are an extension
          of your team, invested in your growth and success.
        </p>

        {showFullText && (
          <>
            <p className="text-lg mb-4">
              We take pride in the long-lasting relationships we've built,
              continuously working to understand our clients' goals and helping
              them achieve new milestones. Our commitment to providing
              exceptional support and value makes us the partner of choice for
              businesses looking to drive efficiency, innovation, and
              sustainable growth.
            </p>
            <p className="text-lg mb-4">
              Our mission is not just about meeting expectations; it's about
              surpassing them and building relationships that last for the long
              term. At BPS, your success is our mission.
            </p>
          </>
        )}

        <button
          onClick={handleReadMoreClick}
          className="font-bold w-32 h-10 text-white bg-primaryYellow rounded-full flex justify-center items-center gap-2 mt-2"
        >
          {showFullText ? "Read Less" : "Read More"}
        </button>
      </div>
      <div className="w-full lg:w-1/2 flex justify-center items-center">
        <img
          src={Icons.I18}
          loading="lazy"
          alt="Our Mission"
          className="rounded-md w-full lg:w-[662px] lg:h-[335px] h-auto"
        />
      </div>
    </div>
  );
};

export default OurMission;
