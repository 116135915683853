import React from "react";
import { Icons } from "../../assets/constant";
import { MdArrowOutward } from "react-icons/md";
import { Link } from "react-router-dom";

const Outsourcing = () => {
  return (
    <div className="p-6 sm:p-8 md:p-12 bg-slate-100">
      <div className="flex flex-wrap md:flex-nowrap gap-5">
        <div className="w-full md:w-1/2 float-left md:float-none">
          <h1 className="text-2xl sm:text-3xl font-bold py-3">
            Why Financial Outsourcing?
          </h1>
          <h2 className="text-xl sm:text-2xl font-medium py-3">
            Empowering Your Business with Strategic Financial Outsourcing
          </h2>
          <p className="text-base sm:text-lg md:text-xl py-3">
            In today's fast-paced business environment, companies are under
            increasing pressure to maintain competitive advantages while
            controlling costs. Financial outsourcing has emerged as a key
            strategy for achieving both goals. By leveraging external expertise
            for critical financial services businesses can optimize efficiency,
            reduce overhead, and scale operations effectively.
          </p>
          <p className="text-sm sm:text-base md:text-lg py-3 italic">
            - Maximize Efficiency, Minimize Costs with Financial Outsourcing
          </p>
          <Link to="/outsourcing">
            <button className="font-bold w-32 h-10 text-white bg-yellow-500 hover:bg-yellow-600 rounded-full flex justify-center items-center gap-2">
              Read More <MdArrowOutward />
            </button>
          </Link>
        </div>

        <div className="w-full md:w-1/2 float-right lg:float-none">
          <img
            src={Icons.I10}
            loading="lazy"
            alt="Financial Outsourcing"
            className="rounded-md w-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Outsourcing;
