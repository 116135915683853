

import React from "react";
import { Icons } from "../../assets/constant";

const Employer = () => {
  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5">
      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-3">
          Employer of Choice
        </h1>
        <p className="text-sm lg:text-xl leading-relaxed">
          We're proud to be recognized as an employer of choice by our dedicated
          team members. BPS fosters a culture where talent thrives, growth is
          supported, and contributions are celebrated. We’re committed to
          offering a collaborative environment that promotes personal and
          professional development. Our team is our greatest asset, and we
          strive to provide opportunities for advancement, recognition, and a
          work-life balance that benefits everyone.
        </p>
      </div>
      <div className="w-full md:w-1/2">
        <img
          src={Icons.A2}
          loading="lazy"
          alt="Employer of Choice"
          className="w-full md:w-[620px] h-auto md:h-[300px] rounded-md"
        />
      </div>
    </div>
  );
};

export default Employer;
