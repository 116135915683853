import React, { useRef, useState } from "react";
import { FaArrowLeftLong, FaArrowRightLong, FaStar } from "react-icons/fa6";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

const testimonial = {
  heading: "Client Testimonials",
  subheading: "What they’re saying?",
  description: "Driven by Excellence, Powered by Satisfaction",
};

const testimonialdata = [
  {
    subheading: "Director - M&A, Healthcare Advisory Firm US",
    description:
      "I consider BPS an integral part of my deal team. They enable us to increase leverage and accelerate work by orders of magnitude greater than what our onshore team can accomplish along Having worked with the team for more than three years and across several firms, I cannot recommend them highly enough.",
  },
  {
    subheading: "CFO, Growth Advisory Firm US",
    description:
      "BPS has been a tremendous partner and collaborator. Strategically, they have helped us convert accounting platforms, streamline processes and pushed us to a whole new level of rigor in the finance and accounting function. They are extremely accountable and hold to committed dates. They have a high attention to detail and the trust I now have in them is extremely high - not just because they consistently get things done on time to a high-quality standard, but they are also great people who truly care about their work.",
  },
  {
    subheading: "Managing Director Growing Consulting Firm",
    description:
      "Bharat, Pankaj and the entire BPS team have grown to be an extension of our team in the US. They are critical in allowing us to successfully serve our clients on complex transactions on tight timelines. The team has grown and developed substantially over the last several years and is up for any challenge presented to them whether it be an incredibly tight deadline or a new complex analysis that they have not tackled before.",
  },
  {
    subheading: "Managing Director Growing Consulting Firm",
    description:
      "BPS is an integral part of our team and provides critical support which allows us to expedite timelines, turn around key deal analysis quickly and deliver high quality work products. BPS has a world class team with a great work ethic. The team’s dedication to perfection enhances the quality of our deliverables and gives us the confidence that analyses are completed with high integrity.",
  },
  {
    subheading: "CFO, Healthcare/Pharmacy Business firm Midwest US",
    description:
      "As an early-stage growth company, our finance function has to stay nimble with the entrepreneurial vision of our company founder. For the last three years, BPS has proven to be a reliable partner with an inclination to contribute to our business's ever-changing demands. Additionally, we recently had employee turnover on our finance team and BPS was critical to the continuity of the finance function. Their team has consistently demonstrated the highest degree of responsiveness while delivering the highest quality work. I consider the BPS team to be an indispensable partner to our firm.",
  },
];

const Integrity = () => {
  const [isPaused, setIsPaused] = useState(false);
  const carouselRef = useRef(null);

  const handleNext = () => {
    carouselRef.current?.next();
  };

  const handlePrev = () => {
    carouselRef.current?.previous();
  };

  return (
    <div className="flex flex-col">
      <div className="py-9 px-5 xs:p-9 sm:p-12 flex justify-center bg-[#f3f4f6]">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          <div className="flex flex-col">
            <p className="text-primaryYellow font-semibold text-base">
              {testimonial.heading}
            </p>
            <p className="text-4xl font-bold mt-2 mb-5">
              {testimonial.subheading}
            </p>
            <p className="mb-3 text-black text-base">
              {testimonial.description}
            </p>
            <div className="flex gap-5">
              <div
                className="w-[42px] h-[42px] rounded-full bg-white shadow-md flex justify-center items-center cursor-pointer"
                onClick={handlePrev}
              >
                <FaArrowLeftLong />
              </div>
              <div
                className="w-[42px] h-[42px] rounded-full bg-primaryYellow flex justify-center items-center text-white cursor-pointer"
                onClick={handleNext}
              >
                <FaArrowRightLong />
              </div>
            </div>
          </div>
          <div className="sm:col-span-1 md:col-span-2 lg:col-span-3 px-0 xs:px-20 md:px-0">
            <Carousel
              arrows={false}
              ref={carouselRef}
              responsive={responsive}
              infinite={true}
              autoPlay={isPaused ? false : true}
              autoPlaySpeed={2000}
              pauseOnHover={false}
              className="grid grid-cols-3"
            >
              {testimonialdata.map((test, index) => (
                <TestimonialCard
                  key={index}
                  testimonial={test}
                  index={index}
                  setIsPaused={setIsPaused}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialCard = ({ testimonial, setIsPaused }) => {
  const [expanded, setExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
    if (!expanded && isHovered) {
      setIsPaused(true);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (expanded) {
      setIsPaused(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setExpanded(false);
    setIsPaused(false);
  };

  return (
    <div
      className="flex flex-col bg-white rounded-lg shadow-lg p-5 xss:p-10 sm:p-5 my-3 m-2"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <p
        className={`text-sm text-black overflow-hidden ${
          expanded ? "" : "line-clamp-6"
        }`}
      >
        {testimonial.description}
      </p>
      <div className="flex justify-start">
        <button
          className="text-blue-500 mt-2 text-sm"
          onClick={handleToggleExpand}
        >
          {expanded ? "Show Less" : "Show More"}
        </button>
      </div>
      <div className="flex justify-between items-start gap-2 pt-10">
        <p className="font-semibold text-xs h-[50px] sm:h-[70px] lg:h-[50px] text-black">
          {testimonial.subheading}
        </p>
        <p className="flex gap-1 text-[#FFC32B]">
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
        </p>
      </div>
    </div>
  );
};

export default Integrity;
