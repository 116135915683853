

import React, { useState } from "react";
import { Icons } from "../../assets/constant";
import { MdArrowOutward } from "react-icons/md";

const Dynamic = () => {
  const [showFullText, setShowFullText] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5">
      <div className="w-full md:w-1/2">
        <img
          src={Icons.A9}
          loading="lazy"
          alt="Our Growth Journey"
          className="w-full md:w-[620px] md:h-[300px] h-auto rounded-md"
        />
      </div>

      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-2">Hiring Journey</h1>
        <p className="text-sm lg:text-xl leading-relaxed">
          At BPS, we believe that our success is driven by the talent and
          dedication of our team. That's why we are committed to hiring dynamic,
          hardworking professionals who are passionate about making a real
          impact. Our hiring process is designed to attract top-tier talent
          while ensuring a seamless and engaging experience for every candidate.
        </p>
        <p className="text-sm lg:text-xl leading-relaxed">
          From the moment you apply, we focus on understanding your unique
          skills, values, and potential. Our thorough background verification
          process ensures that we bring onboard individuals who align with our
          values of integrity, professionalism, and commitment.
        </p>

        {showFullText && (
          <>
            <p className="text-sm lg:text-xl leading-relaxed mt-4">
              Once you're on board, we guide you through a comprehensive
              induction and orientation program, designed to immerse you in our
              culture and set you up for success. We believe in the power of
              teamwork, and our team-building activities foster strong bonds
              among colleagues, creating a supportive and collaborative
              environment.
            </p>
            <p className="text-sm lg:text-xl leading-relaxed mt-4">
              To empower our team members, we offer ongoing training, providing
              the knowledge and tools they need to excel in their roles. After
              completing this immersive journey, we place our new hires on live
              projects, giving them opportunities to contribute to impactful
              solutions.
            </p>
            <p className="text-sm lg:text-xl leading-relaxed mt-4">
              At BPS, we invest in our people, nurturing their growth and
              development while creating a workplace where innovation and
              success thrive. Join us and become a part of a team that’s not
              only shaping the future of business but also making a lasting
              impact.
            </p>
          </>
        )}
        <button
          onClick={handleReadMoreClick}
          className="font-bold w-32 h-10 text-white bg-yellow-500 hover:bg-yellow-600  rounded-full flex justify-center items-center gap-2 mt-2"
        >
          {showFullText ? "Read Less" : "Read More"}
        </button>
      </div>
    </div>
  );
};

export default Dynamic;
