import React from "react";
import { Icons } from "../../assets/constant";

const Empowering = () => {
  const services = [
    {
      title: "Budgeting and Forecasting:",
      description:
        "We support corporate finance teams in the critical annual budgeting process and ongoing monthly forecasts. Our team’s expertise enables us to provide these services at up to 80% lower cost compared to traditional in-house teams. By working closely with internal teams, we expedite the budgeting and forecasting process, ensuring timely and accurate preparation, even during the busiest periods. We focus on delivering detailed financial projections that align with the organization's goals and operational realities.",
      iconSize: 10,
    },
    {
      title: "Comparative Analysis:",
      description:
        "Once budgets and forecasts are established, it’s vital to track actual performance against projections. We provide detailed variance analysis, comparing actual results with budgeted figures. If granted access to transaction-level data, our team can perform a granular analysis to identify the root causes of discrepancies, such as cost overruns or revenue shortfalls. This insight allows businesses to take corrective actions, refine strategies, and optimize financial performance.",
      iconSize: 10,
    },
    {
      title: "Forecast Revisions:",
      description:
        "Financial forecasts are continuously evolving in response to changing market conditions and business performance. Our team helps businesses revise forecasts over rolling periods, ensuring that financial plans remain adaptable and reflective of real-time conditions. By analysing market shifts, internal operations, and external factors, we provide timely recommendations for adjustments that align with the company's evolving strategic goals.",
      iconSize: 10,
    },
    {
      title: "Sales & Cash Register Maintenance:",
      description:
        "We offer support in maintaining daily, weekly, or monthly sales records and reconcile them with actual sales data. This process ensures accuracy in reporting and helps companies maintain an up-to-date view of their cash flows. By keeping sales records organized and reconciled, we assist in improving the accuracy of financial reporting, streamlining operations, and enabling more effective cash flow management.",
      iconSize: 10,
    },
  ];

  return (
    <div className="mt-10 md:pt-20 py-9 px-5 xs:px-9 sm:px-12">
      <h1 className="text-xl md:text-2xl font-bold text-start mb-6">
        Financial Planning and Analysis: Empowering Smarter Financial Decisions
      </h1>
      <div className="flex flex-col md:flex-row items-center justify-between mb-8 gap-3">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <p className="mt-4 text-sm sm:text-base md:text-lg">
            At BPS, we provide robust backend support for Financial Planning and
            Analysis (FP&A) teams, assisting with budgeting, forecasting, and
            comprehensive financial analysis. Our skilled and experienced
            professionals work seamlessly alongside corporate finance teams,
            leveraging advanced tools and analytical expertise to deliver
            precise and timely insights, all at a fraction of the cost.
          </p>
        </div>
        <div className="">
          <img
            src={Icons.I4}
            alt="Financial Due Diligence"
            className="rounded-lg shadow-lg w-[500px] h-[200px]"
          />
        </div>
      </div>
      <h1 className="text-base sm:text-xl font-semibold py-5">
        Our FP&A services include:
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {services.map((service, index) => (
          <div key={index} className="border p-6 rounded-lg shadow-lg">
            <h2 className="text-base sm:text-xl  font-semibold mb-4">
              {service.title}
            </h2>
            <p className="text-sm sm:text-base md:text-lg">
              {service.description}
            </p>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <p className="text-sm sm:text-base md:text-lg">
          Our dedicated professionals, always eager to learn and adapt to new
          challenges, provide the expertise needed to empower your financial
          teams. Let BPS be your partner in achieving seamless financial
          planning, analysis, and optimization.
        </p>
      </div>
    </div>
  );
};

export default Empowering;
