

import React from "react";
import { Icons } from "../../assets/constant";

const Innovation = () => {
  return (
    <div className="flex flex-col lg:flex-row p-12 gap-8">
      <div className="flex flex-col justify-center lg:w-1/2">
        <h1 className="text-3xl font-bold mb-4 text-center lg:text-left">
          Innovation and Impact: Making a Difference Together
        </h1>
        <p className="py-5 text-lg font-medium text-justify md:text-xl">
          At BPS, we are passionate about innovation and making a positive
          impact. We encourage our team members to think creatively, challenge
          the status quo, and bring forward new ideas that push boundaries. As
          part of our team, you'll have the opportunity to contribute to
          meaningful projects that shape the future of our industry. Your work
          matters, and together, we'll create lasting change.
        </p>
        <p className="py-5 text-lg font-medium text-justify md:text-xl">
          Life at BPS isn't just about the job—it's about being part of a
          vibrant, supportive, and rewarding team. Here, every employee is
          empowered to grow, succeed, and be their best self. Join us, and
          experience what it's like to be part of a company that invests in your
          future, celebrates your achievements, and supports you every step of
          the way.
        </p>
      </div>
      <div className="flex justify-center lg:w-1/2">
        <img src={Icons.L13} alt="Innovation" className="rounded-md" />
      </div>
    </div>
  );
};

export default Innovation;
