

import React from "react";
import { GiCheckMark } from "react-icons/gi";
import { Icons } from "../../assets/constant";
import { IoShieldCheckmarkSharp } from "react-icons/io5";

const achievements = [
  { label: "Projects Delivered within time", value: "99.2%" },
  { label: "Referral Rate", value: "125%" },
  { label: "Client Retention Rate (Since Inception)", value: "97.2%" },
  { label: "% of Clients Who Would Recommend us", value: "97%" },
  {
    label: "% of Team with Advanced Degrees",
    value: "90% (MBA, CPA equivalent, CA, ACCA or similar)",
  },
  {
    label: "Employee Satisfaction Rate",
    value: "94% (Based on Glassdoor and Google reviews)",
  },
  { label: "LinkedIn Network", value: "7,000+ followers (as of Jan 2025)" },
];

const ClientRetention = () => {
  return (
    <>
      <h1 className="text-center font-bold s:text-xl lg:text-3xl mt-10">
        Achievements of Our BPS Team
      </h1>
      <div className="w-full p-12 flex flex-col lg:flex-row gap-8">
        <div className="flex-1">
          <h1 className="s:text-xl lg:text-3xl font-semibold py-5">
            Driven by Excellence, Powered by Satisfaction
          </h1>
          <ul className="space-y-3 text-lg">
            {achievements.map((achievement, index) => (
              <li key={index} className="flex gap-3 items-center">
                <IoShieldCheckmarkSharp className="text-green-600 hidden md:block" />
                <p>
                  <strong>{achievement.label}:</strong> {achievement.value}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-center items-center flex-1">
          <img
            src={Icons.I17}
            loading="lazy"
            alt="Team Achievement"
            className="rounded-md w-full lg:w-[500px] h-auto"
          />
        </div>
      </div>
    </>
  );
};

export default ClientRetention;
