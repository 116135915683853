

import React, { useState } from "react";
import { Icons } from "../../assets/constant";
import { MdArrowOutward } from "react-icons/md";

const Ourteam = () => {
  const [showMore, setShowFullText] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullText(!showMore);
  };

  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5">
      <div className="w-full md:w-1/2">
        <img
          src={Icons.A7}
          alt="Our Team"
          loading="lazy"
          className="object-cover rounded-md w-[620px] h-[300px]"
        />
      </div>

      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-3">Our Team</h1>
        <p className="text-sm lg:text-xl leading-relaxed">
          At BPS, our workforce is the cornerstone of our success. We are proud
          to have a team of dedicated and hardworking professionals who
          consistently demonstrate a strong commitment to excellence. Among our
          talented employees are qualified Chartered Accountants (CA) (CPA
          equivalent), ACCA, CMA, CFA as well as postgraduates (PGDM and MBA)
          with vast experience of working on FDD, FP&A, Taxation, Reporting and
          other F&A Support.
        </p>
        {showMore && (
          <p className="text-sm md:text-xl leading-relaxed mt-4">
            We prioritize the continuous development of our team by providing
            comprehensive training, mentorship, and opportunities for career
            progression. At BPS, we are dedicated to nurturing and supporting
            the growth of our employees, ensuring that they are equipped with
            the knowledge and skills to thrive in their careers and contribute
            to the ongoing success of the firm.
          </p>
        )}
        <button
          onClick={handleReadMoreClick}
          aria-expanded={showMore}
          className="font-bold w-36 h-10 text-white bg-yellow-500 hover:bg-yellow-600 rounded-full flex justify-center items-center gap-2 mt-4"
        >
          {showMore ? "Read Less" : "Read More"}
        </button>
      </div>
    </div>
  );
};

export default Ourteam;
