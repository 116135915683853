import React from "react";
import { Icons } from "../../assets/constant";

const Modeling = () => {
  const services = [
    {
      title: "Budgeting and Forecasting:",
      description:
        "We create dynamic models that help businesses develop top-down or bottoms-up budgets by Business units, Locations or Legal entities. These models allow for dynamic inputs which provides flexibility to the Finance teams to create a high level or detailed budget. The actuals vs budget variance dashboard helps in financial planning and improve decision-making.",
      iconSize: 10,
    },
    {
      title: "3 statement financials Forecast:",
      description:
        "We create the 3 or 5 year forecast of Income Statement, Balance Sheet and Cash Flow Statement to assess the business future value and key growth drivers. We have developed models for various industries like Construction, Retail, Wholesalers, Hospitality, Manufacturing and Healthcare.",
      iconSize: 10,
    },
    {
      title: "Valuation Models:",
      description:
        "Our experts can build and review valuation models, including Discounted Cash Flow (DCF), Comparable Company Analysis (CCA), and Precedent Transaction Analysis (PTA), Asset-based Valuation tailored to your business needs for mergers, acquisitions, and investment decisions.",
      iconSize: 10,
    },
    {
      title: "13 weeks Cash Flow Models:",
      description:
        "We design comprehensive cash flow models to help you understand liquidity, manage working capital, and ensure financial stability. These models are updated weekly as a rolling forecast to help businesses prioritize payments, manage liquidity, and plan for potential financing needs. ",
      iconSize: 10,
    },
    {
      title: "Lender Reporting and Management Reporting Packages:",
      description:
        "Our team builds a lender reporting package which includes a set of financial and operational reports prepared by a borrower to provide lenders with regular updates on the company’s performance and financial health. It typically includes financial statements (Income Statement, Balance Sheet, and Cash Flow Statement), covenant compliance certificates, and key performance metrics. In addition, we also build Management Reporting Packages which are provided to company leadership to aid in decision-making and performance monitoring. It typically includes financial statements, budget vs. actual analysis, key performance indicators (KPIs), and operational metrics tailored to the company's business needs.",
      iconSize: 10,
    },
  ];

  return (
    <div className="mt-10 md:pt-20 py-9 px-5 xs:px-9 sm:px-12">
      <h1 className="text-xl md:text-2xl font-bold text-start mb-6">
        Financial Modeling: Strategic Insights for Informed Decision-Making{" "}
      </h1>

      <div className="flex flex-col md:flex-row items-center justify-between mb-8 gap-3">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <p className="mt-4 text-sm sm:text-base md:text-lg">
            At BPS, we specialize in delivering advanced financial modeling
            solutions that provide actionable insights for business growth, risk
            management, and strategic planning. Our team of experts works
            closely with clients to develop robust, data-driven models that
            support complex decision-making and enhance financial forecasting.
          </p>
        </div>
        <div className="">
          <img
            src={Icons.I4}
            alt="Financial Due Diligence"
            className="rounded-lg shadow-lg w-[500px] h-[200px]"
          />
        </div>
      </div>
      <h1 className="text-base sm:text-xl font-semibold py-5">
        Our Financial Modeling services include:
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {services.map((service, index) => (
          <div key={index} className="border p-6 rounded-lg shadow-lg">
            <h2 className="text-base sm:text-xl font-semibold mb-4">
              {service.title}
            </h2>
            <p className="text-sm sm:text-base md:text-lg">
              {service.description}
            </p>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <p className="text-sm sm:text-base md:text-lg">
          BPS offers custom financial models that are not only accurate but also
          flexible, helping you adapt to changing business environments while
          making well-informed decisions. Let us help you navigate the
          complexities of financial planning with precision and confidence.
        </p>
      </div>
    </div>
  );
};

export default Modeling;
