import logo from "../assets/logo.svg";
import HeaderImage from "../assets/HeaderImage.png";
import HeaderImg from "../assets/HeaderImg.jpeg";
import blog1 from "../assets/blog1.jpeg";
import blog2 from "../assets/blog2.jpeg";
import blog3 from "../assets/blog3.jpeg";
import testImage from "../assets/testimonial.jpeg";
import ourTeam1 from "../assets/ourTeam1.jpeg";
import ourTeam2 from "../assets/ourTeam2.jpeg";
import ourTeam3 from "../assets/ourTeam3.jpeg";
import ourTeam4 from "../assets/ourTeam4.jpeg";
import service1 from "../assets/service1.jpeg";
import service2 from "../assets/service2.jpeg";
import service3 from "../assets/service3.jpeg";
import service4 from "../assets/service4.jpeg";
import aboutUsImage from "../assets/aboutImage.png";
import rectangle from "../assets/Rectangle.png";
import arrow from "../assets/arrow.png";
import servicesRectangle from "../assets/ServicesRectangle.png";
import YellowRectangle from "../assets/RectangleYellow.png";
import WhyBpsImage from "../assets/WhyBpsImage.png";
import faqBgImage from "../assets/faqBgImage.jpeg";
import aboutPageImage from "../assets/aboutPageImage.jpeg";
import aboutCheckIcon from "../assets/AboutCheckIcon.png";
import teamMembersBgImage from "../assets/membersBgImage.jpeg";

import meetIcon from "../assets/meetIcon.png";
import happyClient from "../assets/happyClients.svg";
import doneAwards from "../assets/doneAwards.svg";
import doneProjects from "../assets/doneProject.svg";
import aboutImage from "../assets/About.png";
import TheTeam1 from "../assets/TheTeam1.svg";
import TheTeam2 from "../assets/TheTeam2.svg";
import TheTeam3 from "../assets/TheTeam3.svg";
import TheTeam4 from "../assets/TheTeam4.svg";
import TheTeam5 from "../assets/TheTeam5.svg";
import TheTeam6 from "../assets/TheTeam6.svg";
import instagram from "../assets/Instagram.svg";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/Twitter.svg";
import telegram from "../assets/Telegram.svg";
import categoriesIcons from "../assets/categoriesIcon.svg"
import I1 from "../assets/image1.png";
import I2 from "../assets/image2.png";
import I3 from "../assets/image3.jpg";
import I4 from "../assets/image4.jpg";
import I5 from "../assets/image5.png";
import I6 from "../assets/image6.jpg";
import I7 from "../assets/image7.jpg";
import I8 from "../assets/image8.png";
import I9 from "../assets/image9.png";
import I10 from "../assets/image10.png";
import I11 from "../assets/image11.jpg";
import I12 from "../assets/image12.jpg";

import I13 from "../assets/image13.png";
import I14 from "../assets/image14.jpg";
import I15 from "../assets/image15.jpg";
import I16 from "../assets/image16.jpg";
import I17 from "../assets/image17.jpg";
import C1 from "../assets/C1.jpg";
import C2 from "../assets/C2.png";
import A1 from "../assets/i1.png"
import A2 from "../assets/i2.png"
import A3 from "../assets/i3.png"
import A4 from "../assets/i4.png"
import A5 from "../assets/i5.png"
import A6 from "../assets/i6.jpg"
import A7 from "../assets/i7.png"
import A8 from "../assets/i8.png"
import A9 from "../assets/i9.jpg"
import A10 from "../assets/i10.jpg"
import I18 from "../assets/image18.png";
import I19 from "../assets/image19.png";
import L1 from "../assets/L1.jpg";
import L2 from "../assets/L2.png";
import L3 from "../assets/L3.png";
import L4 from "../assets/L4.png";
import L5 from "../assets/L5.png";
import L6 from "../assets/L6.png";
import L7 from "../assets/L7.png";
import L8 from "../assets/L8.png";
import L9 from "../assets/L9.jpg";
import L10 from "../assets/L10.jpg";
import L11 from "../assets/L11.png";
import L12 from "../assets/L12.png";
import L13 from "../assets/L13.png";
import B1 from "../assets/I1 copy.png";
import B2 from "../assets/I2.jpg";
import B3 from "../assets/I3 copy.png";
import B4 from "../assets/I4.jpg";
import B5 from "../assets/I5 copy.png";
import B6 from "../assets/I6.png";
import F1 from "../assets/F1.png";
import F2 from "../assets/F2.png";
import F3 from "../assets/F3.png";
import F4 from "../assets/F4.jpg";
import F5 from "../assets/F5.png";
import T1 from "../assets/T1.jpg";
import T2 from "../assets/T2.png";
import T3 from "../assets/T3.jpg";
import T4 from "../assets/T4.png";
import T5 from "../assets/T5.png";
import T6 from "../assets/T6.jpg";
import T7 from "../assets/T7.jpg";
import clock from "../assets/clock.png";
import map from "../assets/map.png";
import email from "../assets/email.png";
import calling from "../assets/calling.png";
import google from "../assets/google-icon.png";
import insta from "../assets/insta-icon.png";
import facebookfooter from "../assets/facebook-icon.png";
import linkedin from "../assets/linkedin-icon.png";
import notifybell from "../assets/notifybell.png";
import badgeimage from "../assets/badge-reward-medal-svgrepo-com 1.svg";
import bharatsharma from "../assets/Bharat.jpg";
import pankajanad from "../assets/Pankaj.jpg";
import praful from "../assets/Praful.jpg";



export const Icons = {
    bharatsharma,
    pankajanad,
    badgeimage,
    praful,
    notifybell,
    google,
    insta,
    facebookfooter,
    linkedin,
    clock,
    map,
    email,
    calling,
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,
    A10,
    B1,
    B2,
    B3,
    B4,
    B5,
    B6,
    logo,
    HeaderImage,
    HeaderImg,
    blog1,
    blog2,
    blog3,
    testImage,
    ourTeam1,
    ourTeam2,
    ourTeam3,
    ourTeam4,
    service1,
    service2,
    service3,
    service4,
    aboutUsImage,
    rectangle,
    arrow,
    servicesRectangle,
    YellowRectangle,
    WhyBpsImage,
    faqBgImage,
    aboutPageImage,
    aboutCheckIcon,
    teamMembersBgImage,
    meetIcon,
    happyClient,
    doneAwards,
    doneProjects,
    aboutImage,
    TheTeam1,
    TheTeam2,
    TheTeam3,
    TheTeam4,
    TheTeam5,
    TheTeam6,
    T1,
    T2,
    T3,
    T4,
    T5,
    T6,
    T7,
    instagram,
    facebook,
    twitter,
    telegram,
    categoriesIcons,
    I1,
    I2,
    I3,
    I4,
    I5,
    I6,
    I7,
    I8,
    I9,
    I10,
    I11,
    I12,
    I13,
    I14,
    I15,
    I16,
    I17,
    I18,
    I19,
    C1,
    C2,
    L1,
    L2,
    L3,
    L4,
    L5,
    L6,
    L7,
    L8,
    L9,
    L10,
    L11,
    L12,
    L13,
    F1,
    F2,
    F3,
    F4,
    F5
}