import React, { useState } from "react";
import { Icons } from "../../assets/constant";
import { MdArrowOutward } from "react-icons/md";

const Strategic = () => {
  const [isReadMore, setIsReadMore] = useState(false);

  return (
    <div className="flex flex-col md:flex-row mt-10 md:pt-20 py-9 px-5 xs:px-9 sm:px-12">
      <div>
      <div className="hidden sm:block">
        <img
          src={Icons.I6}
          alt=""
          className="rounded-md w-[300px] lg:w-[500px] h-[200px] float-right ml-5"
        />
      </div>
        <h1 className="text-xl md:text-2xl font-bold py-3">
          Financial and Market Research: Strategic Insights for Smarter
          Decisions
        </h1>
        <p className="py-3 text-sm sm:text-base md:text-lg">
          At BPS, we provide comprehensive Financial and Market Research
          services that empower businesses to fine tune their go-to market
          strategy and make informed, strategic decisions. Our team leverages
          advanced primary research techniques and industry knowledge to deliver
          valuable insights into market trends, competitor dynamics, and
          financial performance.
        </p>
        <p className="py-3 text-sm sm:text-base md:text-lg">
          We specialize in primary research that spans across various industries
          and sectors, providing a deep understanding of emerging trends,
          opportunities, and challenges. Our services include creating Company
          profiles, identifying executives, organizational structures, and key
          decision-makers within target firms, as well as conducting thorough
          research on company locations, geographic markets that impact business
          decisions.{" "}
        </p>
        {isReadMore && (
          <p className="py-3 text-sm sm:text-base md:text-lg">
            From industry research to due diligence and financial modelling, we
            deliver critical insights that help your business seize growth
            opportunities, minimize risks, and enhance operational efficiency.
            With BPS as your trusted partner, you'll gain the intelligence
            needed to stay ahead of market shifts and make data-driven decisions
            that drive profitability and long-term success.
          </p>
        )}
        <button
          onClick={() => setIsReadMore(!isReadMore)}
          className="font-bold w-32 h-10 text-white bg-primaryYellow rounded-full flex justify-center items-center gap-2 mt-2"
        >
          {isReadMore ? (
            "Read Less"
          ) : (
            <>
              Read More <MdArrowOutward />
            </>
          )}
        </button>
      </div>
      <div className="block sm:hidden mt-5">
        <img
          src={Icons.I6}
          alt=""
          className="rounded-md w-full h-[200px] float-right ml-5"
        />
      </div>
    </div>
  );
};

export default Strategic;
