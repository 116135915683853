

import React from "react";
import { Icons } from "../../assets/constant";

const Collaboration = () => {
  return (
    <div className="flex flex-col lg:flex-row p-12 gap-8">
      <div className="flex flex-col justify-center lg:w-1/2">
        <h1 className="text-3xl font-bold mb-4 text-center lg:text-left">
          A Culture of Collaboration: Together We Achieve More
        </h1>
        <p className="py-5 text-lg font-medium text-justify md:text-xl">
          At BPS, collaboration isn't just a buzzword—it's a way of life. We
          believe in the power of working together to solve challenges,
          innovate, and deliver exceptional results. Our team-oriented
          environment encourages open communication, idea-sharing, and mutual
          support. Whether working on a client project or brainstorming new
          strategies, we create an atmosphere where every voice is heard, and
          every contribution is valued. When we collaborate, we succeed
          together.
        </p>
      </div>
      <div className="flex justify-center lg:w-1/2">
        <img
          src={Icons.L11}
          alt="Collaboration"
          className="w-full rounded-md"
        />
      </div>
    </div>
  );
};

export default Collaboration;
