import React from "react";
import { Icons } from "../../assets/constant";
import { Link } from "react-router-dom";

const IsoIec = () => {
  return (
    <div className="w-full h-20 flex justify-center items-center bg-blue-400">
      <h1 className="text-lg font-bold text-center text-white">
        <Link to="/iso" className="flex items-center gap-4">
          <img src={Icons.badgeimage} alt="badgeimage" className="" />
          ISO/IEC 27001:2022 Certified: Your Data, Our Priority
          <img src={Icons.badgeimage} alt="badgeimage" className="" />
        </Link>
      </h1>
    </div>
  );
};

export default IsoIec;

