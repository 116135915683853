import React from "react";
import { Icons } from "../../assets/constant";
import { FaCheck } from "react-icons/fa";

const Team = () => {
  const Teamdata = [
    {
      image: Icons.pankajanad,
      heading: ["Mr.Pankaj Anand", "(Co-founder and Director)"],
      subhaeding1: "MBA in Finance and Marketing from IMI, Belgium",
      subhedaing2: "Bachelor in Commerce from Delhi University",
      description:
        "Pankaj has over 15 years of experience working in Multinational corporations including Big 4s and Fortune 500s. He has significant experience in Financial due-diligence, Financial Planning and Analysis, Business Modeling and Pricing. He has led a segment of business with one of the IT majors from US and spent over 3 years with Big 4s in Financial Due Diligence before starting BPS in 2018. Pankaj has primarily worked on buy-side and sell-side financial due diligence deals including few on-site assignments on Divestiture Advisory Services at Client Site in US. He has also worked on various finance tools in the past and has dealt with clients directly over the past few years.",
      description1:
        " He has vast experience in analyzing financial statements, working on financial models, working on quality of earnings, and review of contracts. Pankaj has also handled the team of 8-10 members and lead on multiple projects including building up of complex data book analyses, framing management questions, information request lists, key reconciliations, consistency checks. He is providing technical guidance on projects and contributing to learning and development of the team. Pankaj has also contributed to review of management decks with MIS related decks for management meetings.",
    },
    {
      image: Icons.bharatsharma,
      heading: "Mr.Bharat Sharma (Co-founder and Director)",
      subhaeding1: "CPA from United States, ACCA Certification​",
      subhedaing2: "Bachelor in Commerce from Delhi University​",
      description:
        "Bharat has more than 15 years of experience of working in Multinational corporations with many Fortune 500s. He has spent majority of time into Financial Accounting & Reporting, Financial Planning and Analysis & US Taxes. He has successfully delivered many Accounting & Reporting projects both domestically and remotely.",
      description1:
        " For over 3 years he is also assisting many PE firms in their Financial Due Diligence.",
      description2:
        "He has also run the day-to-day accounting (BPO) business with his last employer Quattro for many big clients which includes:",
      points: [
        "GL Preparation",
        "Trial Balance preparation",
        "Journal Entries",
        "Fixed/Intangible Assets accounting",
        "Bank Reconciliations",
        "P&L and Balance Sheet Finalization",
      ],
      description3:
        "He is avid reader of accounting & reporting structures and suggest the alternatives for accounting & reporting that will minimize the tax burden and efforts on statutory compliances.",
      description4:
        "He is well acquainted with accounting and reporting platforms on MS Office, SAP, Abila MIP, Quick Books, Intacct & Great Plains etc. Prior to BPS, he has worked with Organizations like AIG, Quattro, Reliance Group (India), Adani Group as a finance professional and was responsible for Preparation of Financials Statement, Analysis, ensuring SOX compliance and walkthrough with Internal/Statutory Auditor as necessary.",
    },

    {
      image: Icons.praful,
      heading: "Mr.Praful Lall (Director)",
      subhaeding1:
        "Bachelor in Engineering from Pune University and MBA from Indian Institute of Technology (IIT, Chennai)​",
      subhedaing2:
        "Executive Program in Data Science from Indian Institute of Management (IIM, Calcutta)",
      description:
        "Praful has over 15 years' experience working for EY, Big 4 accounting firm and IBM, a Fortune 100 multinational company.  He has extensive experience in Financial Due Diligence, Data Automation and Analytics and Financial Planning and Analysis (FP&A).",
      description1:
        "During his stint with Big 4, he was part of M&A consulting team advising Fortune 500 Clients on acquisitions, divestitures, spin-offs. He did multiple secondments to US working with CHQ Finance Controllers on financial and accounting requirements. He has experience in developing bespoke solutions to solve critical client needs by leveraging data analytics tools like Microsoft Power BI, Alteryx and predictive, prescriptive analytics using Machine Learning algorithms.",
      description2:
        "Within FP&A function of IBM, Praful handled various roles in financial reporting, budgeting and forecasting, cost optimization.",
    },
  ];

  const Teamexperience = [
    "Advanced Analytical Skills",
    "In-depth Accounting & Financial Knowledge",
    "Expertise of MS Office Tools",
    "Report & Content Writing Excellence",
    "Rigorous Quality Checks & Controls",
    "Proficiency in Alteryx, Power BI, Tableau",
    "Comprehensive Auditing Experience",
    "Cutting-edge Data Analytics",
  ];
  const TeamExposure = [
    "Healthcare & Life-Sciences",
    "Banking & Insurance",
    "Oil & Gas",
    "Real Estate",
    "Retail/FMCG",
    "Information Technology & Telecom",
    "Manufacturing",
  ];

  return (
    <>
      <div className="py-9 px-5 xs:p-9 sm:p-12">
        <div>
          <h1 className="text-2xl md:text-4xl font-bold">
            Our Management Team: Meet the Visionaries Driving Success
          </h1>
        </div>

        {Teamdata.map((Teamdata, index) => (
          <div key={index}>
            <div className="flex flex-col xs:flex-row gap-4">
              <div className="px-0 sm:px-10 pt-14 sm:pt-20 pb-10 xl:py-20 ">
                <img
                  src={Teamdata.image}
                  alt="profile"
                  className="w-[200px] h-[200px] md:w-[250px] md:h-[250px] lg:h-[300px] lg:w-[300px]"
                />
              </div>
              <div className="pt-0 xs:pt-14 sm:pt-20 xs:w-[70%] lg:w-auto">
                <h1 className="text-xl font-bold md:text-2xl md:font-extrabold mb-3">
                  {Teamdata.heading}
                </h1>
                <ul
                  style={{ listStyleType: "square" }}
                  className="px-6 py-4 text-sm sm:text-base md:text-lg "
                >
                  <li className="mb-2 ">{Teamdata.subhaeding1}</li>
                  <li> {Teamdata.subhedaing2}</li>
                </ul>
              </div>
            </div>
            <div className="text-sm sm:text-base md:text-lg px-0 sm:px-10">
              <p className="mb-4">{Teamdata.description} </p>
              <p className="mb-4">{Teamdata.description1}</p>
              <p className="mb-4">{Teamdata.description2}</p>
              {Teamdata.points?.length > 0 ? (
                <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6 w-full lg:w-[80%] xl:w-[50%]">
                  {Teamdata.points.map((point, idx) => (
                    <div key={idx} className="flex items-start space-x-2 ">
                      <FaCheck size={10} className="text-gray-700 mt-1" />
                      <p className="text-base ">{point}</p>
                    </div>
                  ))}
                </ul>
              ) : null}
              <p className="mb-4">{Teamdata.description3}</p>
              <p className="mb-4">{Teamdata.description4}</p>
            </div>
          </div>
        ))}
      </div>

      <div>
        <div className="py-9 px-5  xs:px-9 sm:p-12">
          <h1 className="text-2xl md:text-4xl font-extrabold text-center text-[#0064CB] ">
            BPS Team
          </h1>
          <p className="py-5 text-sm sm:text-base md:text-lg">
            At BPS, our workforce is the cornerstone of our success. We are
            proud to have a team of dedicated and hardworking professionals who
            consistently demonstrate a strong commitment to excellence. Among
            our talented employees are qualified Chartered Accountants (CA) (CPA
            equivalent), individuals pursuing the ACCA, CMA, CFA as well as
            postgraduates (PGDM and MBA) with vast experience of working on FDD,
            FP&A, Taxation, Reporting and other F&A Support. We prioritize the
            continuous development of our team by providing comprehensive
            training, mentorship, and opportunities for career progression. At
            BPS, we are dedicated to nurturing and supporting the growth of our
            employees, ensuring that they are equipped with the knowledge and
            skills to thrive in their careers and contribute to the ongoing
            success of the firm.
          </p>
        </div>
        <div className="grid grid-cols-1 xs:grid-cols-2 px-5 xs:p-9 sm:px-14 xl:px-32 gap-4">
          <div className="flex flex-col items-start">
            <h2 className="text-base font-bold md:text-lg md:font-extrabold text-[#0064CB]">
              Our team brings vast expertise across key areas, including:
            </h2>
            <ul
              style={{ listStyleType: "square" }}
              className="p-2 pb-6 lg:p-6 "
            >
              {Teamexperience.map((experience, index) => (
                <li
                  key={index}
                  className="flex items-center gap-2 pb-2 text-[#54595f] text-sm sm:text-base md:text-lg"
                >
                  <FaCheck className="text-orange-600" /> {experience}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col items-start">
            <h2 className="text-base font-bold md:text-lg md:font-extrabold text-[#0064CB]">
              BPS Team has exposure of working on below industries: -
            </h2>
            <ul className="p-2 pb-6 lg:p-6 ">
              {TeamExposure.map((exposure, index) => (
                <li
                  key={index}
                  className="flex items-center gap-2 pb-2 text-[#54595f] text-sm sm:text-base md:text-lg"
                >
                  {" "}
                  <FaCheck className="text-orange-600" /> {exposure}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="grid grid-rows-2 grid-flow-col gap-4 place-content-center px-5 xs:p-9 sm:px-14 lg:px-20 xl:px-0">
          <div className="col-span-1">
            <img
              src={Icons.T5}
              alt="Team Member 1"
              className="rounded-lg shadow-lg h-[100px] w-[500px] xs:h-[150px] sm:w-[500px] sm:h-[250px] object-fill"
            />
          </div>
          <div className="col-span-1">
            <img
              src={Icons.T6}
              alt="Team Member 2"
              className="rounded-lg shadow-lg h-[100px] w-[500px] xs:h-[150px] sm:w-[500px] sm:h-[250px] object-fill"
            />
          </div>
          <div className="row-span-3">
            <img
              src={Icons.T7}
              alt="Team Member 3"
              className="rounded-lg shadow-lg  h-[210px] w-[500px] xs:h-[314px] sm:w-[500px] sm:h-[514px] object-fill "
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
