import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Icons } from "../../assets/constant";
import { Link } from "react-router-dom";

const Services = () => {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  const allServices = [
    {
      title: "Financial Due-Diligence",
      description:
        "At BPS, we provide critical backend support to our onshore partners in performing Financial Due Diligence, Financial Analysis, and Transaction Advisory Services.",
      image: Icons.I1,
      link: "/financial",
    },
    {
      title: "Integrity Due Diligence",
      description:
        "At BPS, we provide meticulous Integrity Due Diligence (IDD) services designed to safeguard your business interests and ensure transparency in every transaction.",
      image: Icons.I3,
      link: "/integrity",
    },
    {
      title: "Financial Planning and Analysis",
      description:
        "At BPS, we provide robust backend support for Financial Planning and Analysis (FP&A) teams, assisting with budgeting, forecasting, and comprehensive financial analysis.",
      image: Icons.I4,
      link: "/empowering",
    },
    {
      title: "Financial Book Keeping & Month End Close",
      description:
        "At BPS, we offer professional and cost-effective accounting and reporting services tailored to the unique requirements of each industry. ",
      image: Icons.I7,
      link: "/bookkeeping",
    },
    {
      title: "Financial and Market Research",
      description:
        "At BPS, we provide comprehensive Financial and Market Research services that empower businesses to fine tune their go-to market strategy and make informed, strategic decisions.",
      image: Icons.I6,
      link: "/strategy",
    },
    {
      title: "Data Analytics",
      description:
        "Our Data Analytics services transform voluminous and complex datasets into actionable intelligence, helping businesses uncover hidden opportunities, mitigate risks, and optimize performance.",
      image: Icons.I9,
      link: "/analytics",
    },
    {
      title: "Financial Modeling",
      description:
        "At BPS, we specialize in delivering advanced Financial Modeling solutions that provide actionable insights for business growth, risk management, and strategic planning.",
      image: Icons.I8,
      link: "/modeling",
    },
    {
      title: "Other Ad-Hoc Project Support",
      description:
        "At BPS, we provide seamless support for a wide range of ad-hoc projects, ensuring your business operations run smoothly.",
      image: Icons.I5,
      link: "/tailored",
    },
    {
      title: "Restructuring Support",
      description:
        "At BPS, we offer expert Restructuring Support services designed to help businesses navigate complex financial challenges and emerge stronger.",
      image: Icons.I2,
      link: "/restructuring",
    },
    {
      title: "U.S. Taxation Backend Support Services",
      description:
        "At BPS, we provide comprehensive U.S. Taxation Backend Support Services, ensuring that your business stays compliant and optimized within the complex U.S. tax landscape.",
      image: Icons.I5,
      link: "/taxation",
    },
  ];

  const displayedServices = showAll ? allServices : allServices.slice(0, 4);

  return (
    <section className="bg-white py-10">
      <div className="w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 justify-between items-center px-6 sm:px-20">
          <div className="flex flex-col text-center sm:text-left">
            <div data-aos="zoom-in">
              <h1 className="text-base font-semibold tracking-tight text-primaryYellow py-3">
                Services
              </h1>
              <h2 className="text-3xl sm:text-4xl capitalize font-bold tracking-tight text-black">
                Driven by Innovation, Focused on You: Our Services
              </h2>
            </div>
          </div>
        </div>
        <div data-aos="fade-right"> 
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-6 sm:px-20 py-10 bg-white">
            {displayedServices.map((data, index) => (
              <div
                key={index}
                className=" bg-white border-t-8 border-primaryYellow shadow-lg flex flex-col justify-between group transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
              >
                <div className="p-6 flex-grow">
                  <h2 className="flex items-center justify-start space-x-3 pb-2">
                    <img
                      src={Icons.servicesRectangle}
                      className="h-8 w-8"
                      loading="lazy"
                      alt="rectangle"
                    />
                    <div className="text-start text-base text-black capitalize font-bold">
                      {data.title}
                    </div>
                  </h2>
                  <p className="space-x-3 text-sm text-start text-black leading-relaxed">
                    {data.description}
                  </p>
                </div>
                <div className="relative h-[200px] p-2">
                  <img
                    src={data.image}
                    alt={data.title}
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="text-center py-4">
                  <Link
                    to={data.link}
                    className="text-primaryYellow font-medium underline"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <button
            onClick={() => setShowAll(!showAll)}
            className="text-white bg-primaryYellow rounded-full px-5 py-3 text-xs lg:text-sm font-medium animate-slide-up"
          >
            {showAll ? "Show Less Services" : "Find More Services"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Services;