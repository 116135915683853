import React from "react";

function LocationMap() {
  return (
    <div className="py-9 px-5 xs:p-9 sm:p-12">
      <h1 className="text-2xl md:text-4xl font-bold text-start lg:text-center py-8">Our Location</h1>
      <div className="w-full">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14016.701848575222!2d77.261322!3d28.541424!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x39c3b1c9d92f839!2sIndian%20Institute%20of%20Technology%20Delhi!5e0!3m2!1sen!2sin!4v1675123067816!5m2!1sen!2sin"
          width="100%"
          height="541"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="shadow-md"
        ></iframe>
      </div>
    </div>
  );
}

export default LocationMap;
