

import React, { useState } from "react";
import { Icons } from "../../assets/constant";

const Engagement = () => {
  const [zoomedImage, setZoomedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setZoomedImage(imageSrc);
  };

  const closeZoom = () => {
    setZoomedImage(null);
  };

  return (
    <div className="text-center p-12">
      <div>
        <h1 className="text-3xl font-bold mb-6">
          Employee Engagement: Making Every Moment Count
        </h1>
        <p className="py-5 px-4 text-lg leading-relaxed">
          At BPS, we make every moment count. We believe in celebrating our
          team's achievements, big and small. Whether it's celebrating
          birthdays, milestones, or festivals, we come together to create
          memories that last. Our fun-filled engagement activities, annual
          offsite events, and quarterly rewards and recognition events ensure
          that every employee feels appreciated, valued, and part of the BPS
          family. It's all about creating a workplace where joy and teamwork go
          hand in hand!
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
        <img
          src={Icons.L2}
          alt="Engagement 1"
          className="cursor-pointer rounded-lg shadow-md hover:scale-105 transition-transform duration-300"
          onClick={() => handleImageClick(Icons.L2)}
        />
        <img
          src={Icons.L3}
          alt="Engagement 2"
          className="cursor-pointer rounded-lg shadow-md hover:scale-105 transition-transform duration-300"
          onClick={() => handleImageClick(Icons.L3)}
        />
        <img
          src={Icons.L4}
          alt="Engagement 3"
          className="cursor-pointer rounded-lg shadow-md hover:scale-105 transition-transform duration-300"
          onClick={() => handleImageClick(Icons.L4)}
        />
      </div>

      {zoomedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center"
          onClick={closeZoom}
        >
          <img
            src={zoomedImage}
            alt="Zoomed"
            className="max-w-full max-h-full object-contain"
          />
          <button
            onClick={closeZoom}
            className="absolute top-5 right-5 text-white text-3xl font-bold hover:text-gray-300 transition-all"
          >
            ✖
          </button>
        </div>
      )}
    </div>
  );
};

export default Engagement;
