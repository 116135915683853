

import React from "react";
import { Icons } from "../../assets/constant";

const Development = () => {
  return (
    <div className="flex flex-col items-center px-4 py-8">
      <div className="text-center max-w-4xl">
        <h1 className="text-3xl font-bold mb-4">
          Training and Development: Empowering Your Growth
        </h1>
        <p className="py-5 px-4 text-lg font-medium">
          Your professional growth is at the heart of what we do. BPS is
          committed to providing continuous learning opportunities to ensure you
          stay ahead of the curve. With tailored training programs, mentorship,
          and hands-on experience, we nurture your talents and help you unlock
          your full potential. We believe in empowering our team to develop new
          skills, take on leadership roles, and achieve their career
          aspirations. At BPS, your success is our success.
        </p>
      </div>

      <div className="s:grid lg:flex justify-center items-center gap-8 mt-8">
        <img
          src={Icons.L5}
          alt="Training Session"
          className="w-full max-w-[600px] rounded-lg shadow-md object-contain"
        />
        <img
          src={Icons.L6}
          alt="Employee Growth"
          className="w-full max-w-[600px] rounded-lg shadow-md object-contain"
        />
      </div>
    </div>
  );
};

export default Development;
