

import React from "react";
import { Icons } from "../../assets/constant";

const businessApproachData = [
  {
    title: "Tailored Partnerships: Collaborative Growth",
    description:
      " Every business is unique, and so is our approach to partnerships. We invest the time to understand your goals, values, and challenges, crafting personalized strategies that align perfectly with your vision. Together, we foster long-term relationships that drive mutual success and sustainable growth.",
  },
  {
    title: "Core Efficiency: Unlocking Peak Performance",
    description:
      "Efficiency isn't just about doing things faster—it's about doing things smarter. By optimizing every process, resource, and interaction, we unlock the true potential of your operations. We focus on core efficiency to streamline your business, so you can perform at your best, with minimal waste and maximum impact.",
  },
];

const BusinessApproach = () => {
  return (
    <div className="flex flex-col lg:flex-row m-4 md:m-12 gap-5 shadow-lg rounded-md">
      <div className="flex justify-center items-center w-full lg:w-1/2">
        <img
          src={Icons.I16}
          loading="lazy"
          alt="Business Approach"
          className="rounded-md w-full md:w-[662px] md:h-[351px] h-auto"
        />
      </div>

      <div className="w-full lg:w-1/2">
        <ul className="space-y-4">
          {businessApproachData.map((item, index) => (
            <li key={index} className="bg-white p-4 rounded-md shadow">
              <h3 className="text-lg md:text-xl font-semibold text-gray-700">
                {item.title}
              </h3>
              <p className="text-gray-600 mt-2 text-sm md:text-base">
                {item.description}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BusinessApproach;
