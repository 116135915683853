import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <section className="bg-white mx-5 sm:mx-10 md:mx-20 mb-24">
      <div data-aos="zoom-in">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 relative">
          <div className="relative items-center justify-center"></div>
        </div>
      </div>
    </section>
  );
};
export default AboutUs;
