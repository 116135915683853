import React from "react";
import { Icons } from "../../assets/constant";

const Commitment = () => {
  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5">
      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-3">
          Commitment to Excellence
        </h1>
        <p className="text-sm lg:text-xl leading-relaxed">
          Excellence is the cornerstone of everything we do. From the services
          we offer to the people we hire; BPS maintains the highest standards in
          all aspects of our business. Our team is constantly seeking new ways
          to push boundaries, enhance our expertise, and deliver results that
          exceed expectations. This pursuit of excellence is what keeps us ahead
          of the curve and a trusted name in the advisory industry.
        </p>
      </div>

      <div className="w-full md:w-1/2">
        <img
          src={Icons.A5}
          loading="lazy"
          alt="Commitment to Excellence"
          className="w-full h-[300px] object-cover rounded-md"
        />
      </div>
    </div>
  );
};

export default Commitment;
