

import React from "react";
import { Icons } from "../../assets/constant";

const Inclusive = () => {
  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5">
      <div className="w-full md:w-1/2">
        <img
          src={Icons.A1}
          loading="lazy"
          alt="Inclusive and Diverse Environment"
          className="w-full md:w-[620px] md:h-[300px] h-auto rounded-md"
        />
      </div>
      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-3">
          Inclusive and Diverse Environment
        </h1>
        <p className="text-sm lg:text-xl leading-relaxed">
          At BPS, we believe that diversity, equity, and inclusion are essential
          to our success. Our commitment to fostering an inclusive environment
          drives innovation and creativity within our teams. We honour the
          unique perspectives and contributions of every individual, ensuring
          that everyone feels valued, respected, and empowered to bring their
          whole selves to work. This inclusive culture extends beyond our team
          to the way we approach client relationships, building a community that
          celebrates diversity and inclusivity at every level.
        </p>
      </div>
    </div>
  );
};

export default Inclusive;
