import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icons } from "../../assets/constant";
import logo from "../../assets/logo.svg";
import { ReactIcons } from "../../assets/reactIcons";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { BsArrowRightCircleFill } from "react-icons/bs";

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Header = () => {
  const [headerData, setHeaderData] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [isAutoSliding, setIsAutoSliding] = useState(true);
  const autoSlideInterval = useRef(null);

  const location = useLocation();
  const menuRef = useRef();
  const isHomePage = location.pathname === "/";

  const sliderImages = {
    "/": [
      {
        img: Icons.HeaderImg,
        content: {
          title: "Our Services",
          description: "Driven by Innovation, Focused on You: Our Services",
          link: "/allServices",
        },
      },
      {
        img: Icons.I11,
        content: {
          title: "Life at BPS",
          description:
            "Experience the Energy of a Dynamic, Growth-Focused Team at BPS",
          link: "/lifeatbps",
        },
      },
      {
        img: Icons.A6,
        content: {
          title: "About us",
          description: "Who We Are: Your Trusted Partner",
          link: "/aboutus",
        },
      },
      {
        img: Icons.I10,
        content: {
          title: "Why Financial Outsourcing",
          description:
            "Empowering Your Business with Strategic Financial Outsourcing.",
          link: "/outsourcing",
        },
      },
      {
        img: Icons.T1,
        content: {
          title: "Our Team",
          description: "Our People: The Driving Force Behind BPS Excellence",
          link: "/team",
        },
      },
    ],
    "/aboutus": [
      {
        img: Icons.A6,
        content: {
          title: "About us",
          description: "Who We Are: Your Trusted Partner",
        },
      },
    ],
    "/team": [
      {
        img: Icons.T1,
        content: {
          title: "Our Team",
          description: "Our People: The Driving Force Behind BPS Excellence",
        },
      },
    ],
    "/contact-us": [
      {
        img: Icons.I11,
        content: {
          title: "Contact us",
          description:
            "Experience the Energy of a Dynamic, Growth-Focused Team at BPS",
        },
      },
    ],
    "/ourclient": [
      {
        img: Icons.I11,
        content: {
          title: "Our Clients",
        },
      },
    ],
    "/allServices": [
      {
        img: Icons.HeaderImg,
        content: {
          title: "Our Services",
          description: "Driven by Innovation, Focused on You: Our Services",
        },
      },
    ],
    default: [
      {
        img: "",
        content: {
          title: "",
          description: "",
        },
      },
    ],
  };

  const images = sliderImages[location.pathname] || sliderImages.default;

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingMenuItem = headerData.menu?.find(
      (menuItem) => menuItem.link === currentPath
    );
    setActiveMenu(matchingMenuItem?.name || "");
  }, [location.pathname, headerData.menu]);

  useEffect(() => {
    fetch("/data.json")
      .then((response) => response.json())
      .then((data) => setHeaderData(data.header))
      .catch((error) => console.error("Error fetching header data:", error));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
      setShowScrollToTop(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isHomePage && images.length > 1 && isAutoSliding) {
      autoSlideInterval.current = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % images.length);
      }, 4000);
    }
    return () => clearInterval(autoSlideInterval.current);
  }, [images.length, isHomePage, isAutoSliding]);

  const nextSlide = () => {
    clearInterval(autoSlideInterval.current);
    setIsAutoSliding(false);
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    clearInterval(autoSlideInterval.current);
    setIsAutoSliding(false);
    setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentSlide(0);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsServicesOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    setIsServicesOpen(false);
    setIsMenuOpen(false);
  };
  const handleServicesClick = (menuName) => {
    setIsServicesOpen((prev) => !prev);
    setActiveMenu(menuName);
    if (!isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <header className="w-full z-50">
      <div
        className={`fixed top-0 left-0 w-full transition-all duration-300 ease-in-out ${
          isScrolled ? "bg-black shadow-lg" : "bg-transparent"
        } z-50`}
      >
        <div className="relative flex justify-between items-center px-6 py-4 lg:px-12 lg:py-6 z-20">
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="w-56">
            <Link to="/" className="block relative">
              <img
                src={logo}
                alt="logo"
                loading="lazy"
                className="w-36 h-auto lg:w-56"
              />
            </Link>
          </div>

          <button
            className="lg:hidden text-white text-xl z-30"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? "✕" : "☰"}
          </button>

          <nav
            className={`${
              isMenuOpen ? "block" : "hidden"
            } lg:flex lg:items-center lg:space-x-10 absolute lg:static top-14 left-0 w-full lg:w-auto bg-black h-screen lg:h-auto lg:bg-transparent`}
          >
            <ul className="flex flex-col lg:flex-row gap-7 lg:gap-6 p-6 lg:p-0">
              {headerData.menu?.map((menuItem, index) => (
                <li
                  key={index}
                  className="relative"
                  onMouseEnter={() => {
                    if (
                      window.innerWidth >= 1024 &&
                      menuItem.name === "Services" &&
                      !isMenuOpen
                    )
                      setIsServicesOpen(true);
                  }}
                  onMouseLeave={() => {
                    if (
                      window.innerWidth >= 1024 &&
                      menuItem.name === "Services" &&
                      !isMenuOpen
                    )
                      setIsServicesOpen(false);
                  }}
                >
                  {menuItem.name === "Services" ? (
                    <button
                      onClick={() => handleServicesClick(menuItem.name)}
                      className={`block text-white font-semibold py-2 px-3 text-left lg:text-center ${
                        activeMenu === menuItem.name ? "" : ""
                      }`}
                    >
                      {menuItem.name}
                    </button>
                  ) : (
                    <Link
                      to={menuItem.link}
                      className={`block text-white text-sm xl:text-base font-semibold py-2 px-3 text-left lg:text-center ${
                        activeMenu === menuItem.name ? "" : ""
                      }`}
                      onClick={() => handleMenuClick(menuItem.name)}
                    >
                      {menuItem.name}
                    </Link>
                  )}

                  {menuItem.name === "Services" && isServicesOpen && (
                    <ul className="absolute bg-slate-100 text-black rounded-md shadow-md py-3 px-4 z-30 top-10 left-0 lg:-left-[200px] grid grid-cols-1 xs:grid-cols-2 w-[280px] xs:w-[440px] sm:w-[550px]">
                      {menuItem.submenu.map((submenuItem, idx) => (
                        <li key={idx}>
                          <Link
                            to={submenuItem.link}
                            className="block text-black text-sm sm:text-base font-semibold px-2 py-1 xs:p-2 items-center justify-center transition duration-700 ease-in"
                            onClick={() => {
                              handleServicesClick(menuItem.name);
                              setIsServicesOpen(false);
                              setIsMenuOpen(false);
                            }}
                          >
                            {submenuItem.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  <span
                    className={`absolute left-0 -bottom-1 w-[80px] lg:w-full h-0.5 bg-primaryYellow transition-all duration-300 ease-in-out ${
                      menuItem.name !== "Services" &&
                      activeMenu === menuItem.name
                        ? "scale-x-100"
                        : "scale-x-0 "
                    }`}
                  ></span>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {images.length > 0 && images[0].img ? (
        <div
          className={`relative w-full ${
            isHomePage ? "h-[600px]" : "h-[400px] mb-0"
          } overflow-hidden`}
        >
          {isHomePage && images.length > 1 ? (
            <>
              <img
                src={images[currentSlide]?.img}
                alt="slider"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50"></div>
              <div className="absolute z-20 top-1/3 s:left-10 lg:left-32 text-white">
                <h2 className="s:text-2xl lg:text-6xl font-bold">
                  {images[currentSlide]?.content.title}
                </h2>
                <p className="s:text-lg lg:text-xl mt-2">
                  {images[currentSlide]?.content.description}
                </p>
                {images[currentSlide]?.content.link && (
                  <Link to={images[currentSlide]?.content.link}>
                    <button className="font-bold w-32 h-10 text-white bg-primaryYellow rounded-full flex justify-center items-center gap-2 mt-6">
                      Read More
                    </button>
                  </Link>
                )}
              </div>
              <button onClick={prevSlide}>
                <BsArrowRightCircleFill className="absolute top-1/2 right-4 w-11 h-11 text-white hover:text-[#E9A132]" />
                &#10094;
              </button>

              <button onClick={nextSlide}>
                <BsArrowLeftCircleFill className="absolute top-1/2 right-4 w-11 my-16 h-11 text-white hover:text-[#E9A132]" />
                &#10095;
              </button>
            </>
          ) : (
            <>
              <img
                src={images[0]?.img}
                alt="header"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50"></div>
              <div className="absolute z-20 top-1/2 left-10 md:left-32 text-white">
                <h2 className="text-4xl font-bold">
                  {images[0]?.content.title}
                </h2>
              </div>
            </>
          )}
        </div>
      ) : null}

      {showScrollToTop && (
        <button
          onClick={scrollToTop}
          className="fixed z-50 bottom-14 right-4 p-3 rounded-full bg-primaryYellow text-white shadow-lg transition-all hover:bg-black"
          aria-label="Scroll to top"
        >
          <ReactIcons.FaArrowUp />
        </button>
      )}
    </header>
  );
};

export default Header;
