import Footer from './components/Layout/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Layout/Header';

import Services from './components/Home/Services';
import AboutUs from './components/AboutUs/AboutUs';

import BlogDetail from './components/Home/BlogDetail';
import ContactUs from './components/Home/Contact';
import Discover from './components/AboutUs/Discover';
import Growth from './components/AboutUs/Growth';
import Team from './components/AboutUs/OurTeam';
import Focus from './components/AboutUs/Focus';
import Dynamic from './components/AboutUs/Dynamic';
import Employer from './components/AboutUs/Employer';
import Inclusive from './components/AboutUs/Inclusive';
import Corporate from './components/AboutUs/Corporate';
import Vision from './components/AboutUs/Vision';
import Commitment from './components/AboutUs/Commitment';
import Outsourcing from './components/Home/Outsourcing';
import Life from './components/Home/LifeBPS';
import Satisfied from './components/Home/Satisfied';
import BusinessApproach from './components/Home/BusinessApp';
import BusinessTwo from './components/Home/BuinessTwo';
import BusinessThree from './components/Home/BusinessThree';
import BusinessFour from './components/Home/BusinessFour';
import OurMission from './components/Home/OurMission';
import Value from './components/OurClient/ValuedClients';
import Satisfaction from './components/OurClient/Satisfaction';
import Excellence from './components/LifeBPS/Excellence';
import Engagement from './components/LifeBPS/Engagement';
import Development from './components/LifeBPS/Development';
import Rewards from './components/LifeBPS/Rewards';
import Work from './components/LifeBPS/WorkLife';
import Benefits from './components/LifeBPS/Benefits';
import Diversity from './components/LifeBPS/Diversity';
import Collaboration from './components/LifeBPS/Collaboration';
import Career from './components/LifeBPS/Career';
import Innovation from './components/LifeBPS/Innovation';
import BPS from './components/WhyBPS/BPS';
import Finoutsourcing from './components/Outsourching/Outsourcing';
import Financial from './components/Service/Financial';
import Integrity from './components/Service/Integrity';
import Restructuring from './components/Service/Restructuring';
import Taxation from './components/Service/Taxation';
import Empowering from './components/Service/Empowering';
import Book from './components/Service/Bookkeeping';
import Strategy from './components/Service/Strategic';
import Analytics from './components/Service/Analytics';
import Modeling from './components/Service/Modeling';
import Tailored from './components/Service/Tailored';
import Teamscreen from './components/Team/Team';
import LocationMap from './components/Home/location';
import IsoIec from './components/ISO/isoIec';
import ISO27001Certification from './components/ISO/Iso';
import AllServices from './components/Service/AllServices';
import Retention from "./components/Home/ClientRetention";



function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path='/iso' element={<ISO27001Certification />} />
          <Route path="/" element={<>
            <IsoIec />
            <Services limit={4} />
            <Outsourcing />
            <Life />
            <Satisfied />
            <BusinessApproach />
            <BusinessTwo />
            <BusinessThree />
            <BusinessFour />
            <Retention />
            <OurMission />
          </>} />

          <Route path="/aboutus" element={<>
            <AboutUs />
            <Discover />
            <Growth />
            <Team />
            <Focus />
            <Dynamic />
            <Employer />
            <Inclusive />
            <Corporate />
            <Vision />
            <Commitment />
          </>} />

          <Route path="/allServices" element={<AllServices />} />

          <Route path='/financial' element={<Financial />} />
          <Route path='/integrity' element={<Integrity />} />
          <Route path='/restructuring' element={<Restructuring />} />
          <Route path='/taxation' element={<Taxation />} />
          <Route path='/empowering' element={<Empowering />} />
          <Route path='/bookkeeping' element={<Book />} />
          <Route path='/strategy' element={<Strategy />} />
          <Route path='/analytics' element={<Analytics />} />
          <Route path='/modeling' element={<Modeling />} />
          <Route path='/tailored' element={<Tailored />} />


          <Route path="/ourclient" element={<>
            <Value />
            <Satisfaction />
          </>} />

          <Route path="/team" element={<>
            <Teamscreen />
          </>} />

          <Route path="/lifeatbps" element={<>
            <Excellence />
            <Engagement />
            <Development />
            <Rewards />
            <Work />
            <Benefits />
            <Diversity />
            <Collaboration />
            <Career />
            <Innovation />
          </>} />

          <Route path="/whybps" element={<BPS />} />


          <Route path="/outsourcing" element={
            <>
              <Finoutsourcing />

            </>
          } />

          <Route path="/blog-detail" element={<BlogDetail />} />

          <Route path="/contact-us" element={<>
            <ContactUs />
            <LocationMap />
          </>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
