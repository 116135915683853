

import React from "react";
import { Icons } from "../../assets/constant";

const businessApproachData = [
  {
    title: "Improved Margins: Maximizing Profitability",
    description:
      "Unlock the potential for enhanced profitability with our strategic focus on driving efficiencies, reducing costs, and optimizing resources. Our approach is designed to improve margins, ensuring that your bottom line is consistently strengthened through smarter, data-driven decision-making and innovative solutions.",
  },
  {
    title: "Larger Window: Round-the-Clock Availability",
    description:
      "Time is your most valuable asset—and we're here whenever you need us. With 24/7 availability, we offer a larger window of opportunity for you to achieve your business objectives. Whether you’re dealing with a tight deadline or an unexpected challenge, we’ve got you covered around the clock.",
  },
];

const BusinessApproach = () => {
  return (
    <div className="flex flex-col lg:flex-row m-4 md:m-12 gap-5 shadow-lg rounded-md">
      <div className="w-full lg:w-1/2 flex justify-center items-center">
        <img
          src={Icons.I16}
          loading="lazy"
          alt="Business Approach"
          className="rounded-md w-full md:w-[662px] md:h-[351px] h-auto"
        />
      </div>

      <div className="w-full lg:w-1/2">
        <ul className="space-y-4">
          {businessApproachData.map((item, index) => (
            <li key={index} className="bg-white p-4 rounded-md shadow">
              <h3 className="text-lg md:text-xl font-semibold text-gray-700">
                {item.title}
              </h3>
              <p className="text-gray-600 mt-2 text-sm md:text-base">
                {item.description}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BusinessApproach;
