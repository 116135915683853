

import React from "react";
import { Icons } from "../../assets/constant";

const Career = () => {
  return (
    <div className="flex flex-col lg:flex-row p-12 gap-8">
      <div className="flex flex-col justify-center lg:w-1/2">
        <h1 className="text-3xl font-bold mb-4 text-center lg:text-left">
          A Rewarding Career Path: Grow, Achieve, Succeed
        </h1>
        <p className="py-5 text-lg font-medium text-justify md:text-xl">
          Your career at BPS is designed to be fulfilling, challenging, and
          rewarding. We believe in recognizing and celebrating your hard work
          and dedication. From competitive compensation and performance-based
          monthly rewards to opportunities for promotion and professional
          development, we ensure that your career journey is one of constant
          growth and recognition. At BPS, your career path is as unique as you
          are, and we are committed to helping you achieve your goals every step
          of the way.
        </p>
      </div>
      <div className="flex justify-center lg:w-1/2">
        <img src={Icons.L12} alt="Career Path" className="w-full rounded-md" />
      </div>
    </div>
  );
};

export default Career;
