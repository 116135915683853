import React from "react";
import { Icons } from "../../assets/constant";

const Tailored = () => {
  return (
    <div className="flex flex-col md:flex-row mt-10 md:pt-20 py-9 px-5 xs:px-9 sm:px-12">
      <div>
        <div className="hidden sm:block">
          <img
            src={Icons.I12}
            alt=""
            className="rounded-md w-[300px] lg:w-[500px] h-[200px] float-right ml-5"
          />
        </div>
        <h1 className="text-xl md:text-2xl font-bold py-3">
          Other Ad-Hoc Project Support: Tailored Solutions for Every Need
        </h1>
        <p className="py-3 text-sm sm:text-base md:text-lg">
          At BPS, we provide seamless support for a wide range of ad-hoc
          projects, ensuring your business operations run smoothly. Whether it’s
          handling complex data entry from unstructured documents, converting
          PDFs into clean, Excel-ready formats, or performing detailed data
          analysis, we deliver precise and efficient results.
        </p>
        <p className="py-3 text-sm sm:text-base md:text-lg">
          Our expertise extends to analysing data inputs for essential reports,
          including A/R and A/P aging analysis, employee data reviews, and more.
          We cater to every project requirement with a focus on accuracy,
          timeliness, and the highest level of professionalism, ensuring that
          your business stays on track, no matter the challenge.
        </p>
      </div>
      <div className="block sm:hidden mt-5">
        <img
          src={Icons.I12}
          alt=""
          className="rounded-md w-full h-[200px] float-right ml-5"
        />
      </div>
    </div>
  );
};

export default Tailored;
