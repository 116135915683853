import React from "react";
import { Icons } from "../../assets/constant";

const Integrity = () => {
  const services = [
    {
      title: "Analysing Information:",
      description: `We conduct thorough reviews of financial records, corporate history, and operational practices to ensure complete transparency and identify any discrepancies.`,
    },
    {
      title: "Identifying Red Flags:",
      description: `We proactively uncover potential risks such as legal disputes, unethical practices, or financial mismanagement that may pose a threat to your business.`,
    },
    {
      title: "Conducting In-Depth Research:",
      description: `Through a combination of primary and secondary research, we investigate key stakeholders, management teams, and related entities to assess their credibility and trustworthiness.`,
    },
    {
      title: "Providing Actionable Insights:",
      description: `Our findings are distilled into clear, actionable recommendations, empowering you to make well-informed decisions that align with your long-term goals and values.`,
    },
  ];

  return (
    <div className="mt-10 md:pt-20 py-9 px-5 xs:px-9 sm:px-12">
      
      <h1 className="text-xl md:text-2xl font-bold text-start mb-6">
        Integrity Due Diligence: Ensuring Trust and Transparency
      </h1>

      <div className="flex flex-col md:flex-row items-center justify-between mb-8 gap-3">
        <div className="w-full  md:w-1/2 mb-4 md:mb-0">
          <p className="mt-4 text-sm sm:text-base md:text-lg">
            At BPS, we provide meticulous Integrity Due Diligence (IDD) services
            designed to safeguard your business interests and ensure
            transparency in every transaction. Our comprehensive due diligence
            process helps identify potential risks, verify the integrity of
            partners, and assess the reputational and ethical standing of
            individuals and organizations.
          </p>
        </div>

        <div className="">
          <img
            src={Icons.I3}
            alt="Financial Due Diligence"
            className="rounded-lg shadow-lg w-[500px] h-[200px]"
          />
        </div>
      </div>
      <h1 className="text-base sm:text-xl font-semibold py-5">
        Our services include:
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {services.map((service, index) => (
          <div key={index} className="border p-6 rounded-lg shadow-lg">
            <h2 className="text-base sm:text-xl font-semibold mb-4">{service.title}</h2>
            <p className="text-sm sm:text-base md:text-lg">{service.description}</p>
          </div>
        ))}
      </div>

      <div className="mt-8">
      <p className="text-sm sm:text-base md:text-lg">
          Whether you are exploring new partnerships, acquisitions, or
          investments, our Integrity Due Diligence services provide the clarity
          and assurance you need to move forward with confidence. Trust BPS to
          help you protect your reputation, mitigate risks, and ensure your
          business relationships align with the highest standards of integrity.
        </p>
      </div>
    </div>
  );
};

export default Integrity;
