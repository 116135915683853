

import React from "react";
import { Icons } from "../../assets/constant";

const businessApproachData = [
  {
    title: "Data Security: Safeguarding Your Success",
    description:
      "At the heart of our business is the commitment to protecting your data. We implement cutting-edge security measures to ensure that your sensitive information remains confidential, secure, and always within your control. Trust is built on security, and we’ve designed our approach to give you peace of mind every step of the way.",
  },
  {
    title: "Extended Teams: Seamlessly Integrated Expertise",
    description:
      "Our team isn't confined to one location or set of skills—it's extended, diverse, and agile. With access to global talent, we seamlessly integrate experts into your business, delivering the right mix of innovation and experience. This collaboration expands your capability, enhancing your efficiency and results.",
  },
];

const BusinessApproach = () => {
  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5 bg-gray-50 shadow-lg rounded-md">
      <div className="w-full md:w-1/2">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">
          Our Business Approach:
        </h2>
        <ul className="space-y-4">
          {businessApproachData.map((item, index) => (
            <li key={index} className="bg-white p-4 rounded-md shadow">
              <h3 className="text-lg md:text-xl font-semibold text-gray-700">
                {item.title}
              </h3>
              <p className="text-gray-600 mt-2 text-sm md:text-base">
                {item.description}
              </p>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex justify-center items-center w-full md:w-1/2">
        <img
          src={Icons.I15}
          loading="lazy"
          alt="Business Approach"
          className="rounded-md w-full md:w-[621px] md:h-[395px] h-auto"
        />
      </div>
    </div>
  );
};

export default BusinessApproach;
