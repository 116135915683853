import { useEffect, useState } from "react";
import { Icons } from "../../assets/constant";
import { Link } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  const [footerData, setFooterData] = useState({});
  useEffect(() => {
    fetch("/data.json")
      .then((response) => response.json())
      .then((data) => setFooterData(data.footer))
      .catch((error) => console.error("Error fetching footer data:", error));
  }, []);

  const contactDetails = [
    {
      image: Icons.clock,
      text1: "Monday to Friday",
      text2: "09:00 - 20:00 IST",
    },
    {
      image: Icons.map,
      text1:
        "Tower B, 3rd floor, Balaji Estate, 8 Guru Ravidas marg, Kalkaji, New Delhi-110019",
    },
    {
      image: Icons.email,
      text1: "info@bpsanalytics.in",
    },
    {
      image: Icons.calling,
      text1: "+91-11-40244526",
    },
  ];

  return (
    <footer className="flex flex-col">
      <div className=" sm:hidden s:grid grid-cols-2 xss:grid-cols-4 gap-2 md:gap-4 px-6 lg:px-12 md:pb-10 pt-6 py-10 ">
        {contactDetails.map((item, index) => (
          <div
            className="flex flex-col items-center text-white text-xs xss:text-sm xl:text-lg bg-primaryYellow text-center p-2 md:p-4 xl:p-6 "
            key={index}
          >
            <div className="bg-white w-8 h-8 md:w-14 md:h-14 rounded-full flex justify-center items-center p-2 md:p-4 mb-2">
              <img src={item.image} alt="image" className="" />
            </div>
            <h1>{item.text1}</h1>
            <h1>{item.text2}</h1>
          </div>
        ))}
      </div>
      <div className="bg-primaryGray px-4 xs:px-6 lg:px-12 relative mt-0 sm:mt-40">
        <div className="flex flex-col items-center">
          <div className="s:hidden sm:grid grid-cols-4 gap-2 md:gap-4 absolute -top-24 xl:-top-24 px-6 lg:px-12">
            {contactDetails.map((item, index) => (
              <div
                className="flex flex-col items-center text-white text-sm xl:text-lg bg-primaryYellow text-center p-2 md:p-4 xl:p-6"
                key={index}
              >
                <div className="bg-white w-14 h-14 rounded-full flex justify-center items-center p-4 mb-2">
                  <img src={item.image} alt="image" className="" />
                </div>
                <h1>{item.text1}</h1>
                <h1>{item.text2}</h1>
              </div>
            ))}
          </div>
          <div className="flex flex-col sm:flex-row pt-5">
            <div className="w-full sm:w-[40%] md:w-[45%] flex flex-col border-r-0 sm:border-r border-white pr-0 sm:pr-5 xl:pr-16  pt-6 sm:pt-28 xl:pt-36">
              <Link href="/" className="">
                <img
                  src={Icons.logo}
                  className="h-12 w-auto"
                  alt="Footer Logo"
                  loading="lazy"
                />
              </Link>
              <div className="text-start text-gray-300 text-sm mt-3 mb-4 lg:mb-0 pl-0 xs:pl-5">
                {footerData?.description}

                <div className="flex py-7 lg:py-10 gap-5 md:gap-10">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="https://bpsanalytics.in/"
                  >
                    <img
                      src={Icons.google}
                      alt="google"
                      className="w-[29px] h-[29px]"
                    />
                  </Link>
                  <img
                    src={Icons.insta}
                    alt="insta"
                    className="w-[29px] h-[29px]"
                  />
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="https://www.facebook.com/bp.sol.92"
                  >
                    <img
                      src={Icons.facebookfooter}
                      alt="facebook"
                      className="w-[29px] h-[29px]"
                    />
                  </Link>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="https://www.linkedin.com/company/bps-analytics-pvt-ltd/posts/?feedView=all"
                  >
                    <img
                      src={Icons.linkedin}
                      alt="linkedin"
                      className="w-[29px] h-[29px]"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-[60%] md:w-[55%] flex justify-between pt-5 sm:pt-28 xl:pt-36">
              <div className="flex flex-col pl-0 xs:pl-5 lg:pl-14 xl:pl-28">
                {footerData?.footerSections?.map((section, index) => (
                  <div key={index} className="w-full mb-6 md:mb-0">
                    <h2 className="mb-6 text-base text-start font-bold text-white capitalize">
                      {section?.title}
                    </h2>
                    <ul className="text-white text-start text-sm font-normal">
                      <div>
                        {section?.subsections
                          ?.slice(0, 6)
                          .map((subsection, i) => (
                            <li key={i} className="mb-2">
                              <Link
                                to={subsection?.link}
                                className="hover:underline"
                              >
                                {subsection?.name}
                              </Link>
                            </li>
                          ))}
                      </div>
                      <div>
                        {section?.subsections?.slice(6).map((subsection, i) => (
                          <li key={i} className="mb-4">
                            <Link
                              href={subsection.link}
                              className="hover:underline"
                            >
                              {subsection?.name}
                            </Link>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>
                ))}
              </div>
              <div className="w-auto lg:w-[320px] xl:w-[400px] pl-5 md:mb-0 mb-4">
                <h2 className="mb-6 text-base text-start font-bold text-white capitalize">
                  News Letter
                </h2>
                <p className="text-sm text-start font-normal text-white">
                  Don't miss the latest news
                </p>
                <form className="relative w-full my-4">
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full px-3 py-2 xs:py-4 rounded-full text-gray-700 focus:outline-none"
                  />
                  <button
                    type="submit"
                    className="absolute right-0 top-0 bottom-0 bg-primaryYellow text-white px-4 m-2 sm:px-6 rounded-full transition"
                  >
                    Send
                  </button>
                </form>
                <div className="flex gap-2">
                  <div className="w-[30px] h-[30px] rounded-full bg-primaryYellow bg-opacity-[20%] flex justify-center items-center">
                    <img
                      src={Icons.notifybell}
                      alt="notification"
                      className=""
                    />
                  </div>
                  <p className="text-white text-sm">
                    Please sign up for notify any updates
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full text-white py-4 justify-between items-center capitalize bg-[#161519] border-t border-white">
            <div className="text-xs">
              {footerData?.copyRight_Year}
              <Link href="#" className="hover:underline">
                {footerData?.copyRight_Text}
              </Link>
            </div>
            <div className="text-xs flex gap-5">
              <p>Term Of Use</p>
              <p>Privacy policy</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
