import React from "react";
import { FaCheck } from "react-icons/fa";
import { Icons } from "../../assets/constant";

const services = [
  {
    title: "Consulting Firms helping in FDD and other M&A related Activities",
    iconSize: 10,
  },
  {
    title:
      "Healthcare businesses with presence in multiple locations within North America Region and across globe",
    iconSize: 10,
  },
  {
    title: "Optometry Practices & Other related healthcare services",
    iconSize: 10,
  }
]
  const services2 = [
  {
    title: "Industry Benchmarking & Business Enhancements",
    iconSize: 10,
  },
  {
    title: "Auditing and Bookkeeping",
    iconSize: 10,
  },
  {
    title: "CPA Firms & Private Equity Clients",
    iconSize: 10,
  },
  {
    title: "Other Financial Services",
    iconSize: 10,
  },
];

const Service = () => {
  return (
    <div className="py-9 px-5 xs:p-9 sm:p-12">
      <div className="mb-8">
        <h1 className="text-2xl md:text-4xl font-bold md:font-extrabold text-black text-center">
          Our Clients
        </h1>
       
      </div>
      <div className="relative flex flex-col sm:flex-row mt-9 xs:mt-16">
        <div className="w-full sm:w-[40%] ">
          <img
            src={Icons.C2}
            alt="Our Clients"
            className="rounded-md shadow-md w-full h-[300px] xxs:w-[600px] xxs:h-[400px] object-cover"
          />
        </div>
        <div className="w-full sm:w-[60%] sm:pl-5 xl:pl-20">
        <p className="text-xl md:text-xl xl::text-2xl font-semibold text-black mb-4 mt-4 sm:mt-0 ">
        Our Valued Clients: Empowering Success Through Trusted Partnerships
        </p>
          <p className="text-sm sm:text-base md:text-lg text-black mb-2 xl:mb-4">
            We are currently serving more than 30 medium and small businesses
            primarily based out of the North America region from industries like
            Healthcare, Retail, Professional services, and Insurance.
          </p>
          <p className="text-sm sm:text-base md:text-lg text-black mb-2 xl:mb-4">
            BPS aims to continue expanding horizons to serve other businesses
            who are seeking offshoring opportunities to extend their window of
            working hours and seeking operational and financial efficiencies.
            Offshoring not only helps them with extending working hours for
            their clients but also helps in improving margins by up to 80% on
            their projects.
          </p>
          <p className="hidden lg:block text-sm sm:text-base md:text-lg text-black mb-2 xl:mb-6">
            We have partnered with various firms in the North America Region and
            already serve more than 30 clients who are in the following
            businesses:
          </p>
        </div>
      </div>
      <p className="block lg:hidden text-sm sm:text-base md:text-lg text-black mt-2 ">
            We have partnered with various firms in the North America Region and
            already serve more than 30 clients who are in the following
            businesses:
          </p>
      <div className="flex justify-center pt-12 xl:pt-16">
        
        <ul className="w-[100%] flex flex-col xs:flex-row flex-wrap justify-between text-black">
          <div className="w-full xs:w-[50%] pr-5 md:pr-20">
            {services.map((service, index) => (
              <li key={index} className="flex items-start space-x-3 mb-4">
                <FaCheck className={`text-orange-600 ${index===1?"text-2xl xl:text-[22px]":""}`} />
                <span className="text-sm md:text-base font-medium">{service.title}</span>
              </li>
            ))}
          </div>
          <div className="w-full xs:w-[50%]">
            {services2.map((service, index) => (
              <li key={index} className="flex items-start space-x-3 mb-4">
                <FaCheck className="text-orange-600" />
                <span className="text-sm md:text-base font-medium">{service.title}</span>
              </li>
            ))}
          </div>
        </ul>
      </div>
       <p className="text-sm sm:text-base md:text-lg text-black mt-5">
          At BPS, we provide the expertise, precision, and support needed to
          facilitate smooth and successful financial due diligence processes.
          Our commitment to quality ensures your transactions are built on a
          solid foundation of insights and trust.
        </p>
    </div>
  );
};

export default Service;
