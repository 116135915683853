import React from "react";
import { Icons } from "../../assets/constant";

const Bookkeeping = () => {
  const services = [
    {
      title: "GL Preparation:",
      description:
        "We ensure meticulous recording of all day-to-day accounting transactions, in line with accounting standards and policies. Our expert team handles journal entries with precision and maintains consistency across various segments and locations, streamlining the chart of accounts as your relationship with us matures.",
      iconSize: 10,
    },
    {
      title: "Accounts Payable:",
      description:
        "Accuracy and timeliness are our focus in managing your accounts payable. We help streamline invoice processing, vendor reconciliation, and ensure your company meets vendor payment deadlines. Additionally, we offer vendor master data cleanup and updates as needed.",
      iconSize: 10,
    },
    {
      title: "Accounts Receivable:",
      description:
        "Our team efficiently manages thousands of customer records, ensuring timely invoicing and receipt matching. We adhere to accounting principles to guarantee accurate revenue recording, while also assisting with invoice generation and delivery to customers.",
      iconSize: 10,
    },
    {
      title: "Bank Reconciliations:",
      description:
        "Bank reconciliations are essential for accurate financial records. We handle all aspects of reconciliation—from accounts receivables and payables to invoice checking and general ledger accounting. Our team identifies and resolves errors to maintain precise records of all transactions.",
      iconSize: 10,
    },
  ];

  return (
    <div className="mt-10 md:pt-20 py-9 px-5 xs:px-9 sm:px-12">
      <h1 className="text-xl md:text-2xl font-bold text-start mb-6">
        Financial Bookkeeping and Month-End Close: Precision and Timeliness in
        Financial Reporting
      </h1>

      <div className="flex flex-col md:flex-row items-center justify-between mb-8 gap-3">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <p className="mt-4 text-sm sm:text-base md:text-lg">
            At BPS, we offer professional and cost-effective accounting and
            reporting services tailored to the unique requirements of each
            industry. Whether it's insurance, media, retail, manufacturing,
            shipping, telecom, travel, or utilities, we deliver
            industry-specific financial solutions with a focus on accuracy and
            compliance.
          </p>
          <p className="mt-4 text-sm sm:text-base md:text-lg">
            Our experienced team ensures smooth month-end, quarter-end, and
            year-end financial closures by meticulously verifying transactions
            against source documents and recording them accurately in
            appropriate ledgers. We provide complete financial reports,
            including P&L, balance sheets, GL, TB, and bank reconciliations,
            ensuring all financials are available before due dates. We also
            support audits by providing detailed schedules for assets and
            liabilities alongside financial statements.
          </p>
        </div>
        <div className="">
          <img
            src={Icons.I7}
            alt="Financial Due Diligence"
            className="rounded-lg shadow-lg w-[500px] h-[200px]"
          />
        </div>
      </div>
      <h1 className="text-base sm:text-xl font-semibold py-5">
        Our Financial Bookkeeping & Month-End Close services include:
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {services.map((service, index) => (
          <div key={index} className="border p-6 rounded-lg shadow-lg">
            <h2 className="text-base sm:text-xl font-semibold mb-4">{service.title}</h2>
            <p className="text-sm sm:text-base md:text-lg">{service.description}</p>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <p className="text-sm sm:text-base md:text-lg">
          With BPS, you can trust that your finances are in capable hands. We
          streamline your accounting processes and meet deadlines with
          precision, providing you with comprehensive, timely financial insights
          for seamless business operations.
        </p>
      </div>
    </div>
  );
};

export default Bookkeeping;
