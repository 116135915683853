import { FaArrowLeftLong, FaArrowRightLong, FaArrowUp } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";
import { PiPhoneCallLight } from "react-icons/pi";
import { FaRegCirclePlay } from "react-icons/fa6";
import { BiMinus, BiPlus } from "react-icons/bi";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";


export const ReactIcons = {
    PiPhoneCallLight,
    FaArrowLeftLong,
    FaArrowRightLong,
    IoPersonSharp,
    FaRegCirclePlay,
    BiPlus,
    BiMinus,
    FaArrowUp,
    FaLinkedinIn,
    FaTwitter
}