import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { Icons } from "../../assets/constant";
import { toast, ToastContainer } from "react-toastify";

const ContactUs = () => {
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First Name is required")
      .min(2, "Name must be at least 2 characters"),
    subject: Yup.string()
      .required("Subject is required")
      .min(2, "Subject must be at least 2 characters"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be numeric")
      .min(10, "Phone number must be at least 10 digits")
      .required("Phone number is required"),
    query: Yup.string()
      .required("Query is required")
      .min(10, "Query must be at least 10 characters"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      subject: "",
      email: "",
      phone: "",
      query: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const templateParams = {
        firstName: values.firstName,
        subject: values.subject,
        email: values.email,
        phone: values.phone,
        query: values.query,
      };

      emailjs
        .send(
          "service_s7s6wgr",
          "template_b5vn4xj",
          templateParams,
          "bT-zT0qHN3FwnUjfl"
        )
        .then(
          (response) => {
            console.log(
              "Email sent successfully:",
              response.status,
              response.text
            );
            toast.success("Message sent successfully!");
            resetForm();
          },
          (error) => {
            console.error("Failed to send email:", error);
            toast.error("Failed to send the message. Please try again later.");
          }
        );
    },
  });

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="flex flex-col lg:flex-row bg-white w-full p-5 lg:p-10">
        <div className="flex flex-col lg:w-1/2 px-0 xs:px-4 lg:px-6 ">
          <div className="text-start">
            <h2 className="text-base font-semibold tracking-tight text-primaryYellow">
              Have Any Question?
            </h2>
            <h3 className="text-2xl md:text-4xl capitalize font-bold tracking-tight text-black">
              Write A Message
            </h3>
          </div>
          <form onSubmit={formik.handleSubmit} className="py-4 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Name"
                  className={`w-full p-4 bg-gray-100 rounded-md outline-none ${
                    formik.touched.firstName && formik.errors.firstName
                      ? "border-red-500 focus:ring-red-500"
                      : "focus:ring-yellow-500"
                  }`}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <p className="text-red-500 text-sm">
                    {formik.errors.firstName}
                  </p>
                )}
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className={`w-full p-4 bg-gray-100 rounded-md outline-none ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500 focus:ring-red-500"
                      : "focus:ring-yellow-500"
                  }`}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-red-500 text-sm">{formik.errors.email}</p>
                )}
              </div>
              <div>
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  className={`w-full p-4 bg-gray-100 rounded-md outline-none ${
                    formik.touched.phone && formik.errors.phone
                      ? "border-red-500 focus:ring-red-500"
                      : "focus:ring-yellow-500"
                  }`}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <p className="text-red-500 text-sm">{formik.errors.phone}</p>
                )}
              </div>
              <div>
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  className={`w-full p-4 bg-gray-100 rounded-md outline-none ${
                    formik.touched.subject && formik.errors.subject
                      ? "border-red-500 focus:ring-red-500"
                      : "focus:ring-yellow-500"
                  }`}
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <p className="text-red-500 text-sm">
                    {formik.errors.subject}
                  </p>
                )}
              </div>
            </div>
            <div>
              <textarea
                name="query"
                placeholder="Message"
                className={`w-full p-4 bg-gray-100 rounded-md outline-none h-40 ${
                  formik.touched.query && formik.errors.query
                    ? "border-red-500 focus:ring-red-500"
                    : "focus:ring-yellow-500"
                }`}
                value={formik.values.query}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.query && formik.errors.query && (
                <p className="text-red-500 text-sm">{formik.errors.query}</p>
              )}
            </div>
            <button
              type="submit"
              className="bg-yellow-500 text-white py-3 px-6 rounded-md hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-400"
            >
              Send A Message
            </button>
          </form>
        </div>
        <div className="flex flex-col lg:w-1/2 p-0 xs:px-4 lg:px-10 mt-10">
          <div className="text-start">
            <h2 className="text-base font-semibold tracking-tight text-primaryYellow">
              Contact With Us
            </h2>
            <h3 className="text-2xl md:text-4xl capitalize font-bold tracking-tight text-black">
              Let’s work together
            </h3>
            <p className="text-sm font-normal capitalize pt-3 ">
              Providing legal advice, contract drafting, compliance assistance,
              intellectual property protection, and other legal support for
              businesses.
            </p>
          </div>
          <div className="flex flex-row space-x-3 py-4">
            <img
              src={Icons.YellowRectangle}
              alt="YellowRectangle"
              className="h-8 w-8 xss:h-12 xss:w-12"
            />
            <div className="text-start">
              <h2 className="text-sm font-normal tracking-tight text-primaryYellow">
                Have Any Question?
              </h2>
              <h3 className="text-base md:text-lg capitalize font-semibold tracking-tight text-black">
                +91-11-40244526
              </h3>
            </div>
          </div>
          <div className="flex flex-row space-x-3 py-2">
            <img
              src={Icons.YellowRectangle}
              alt="YellowRectangle"
              className="h-8 w-8 xss:h-12 xss:w-12"
            />
            <div className="text-start">
              <h2 className="text-sm font-normal tracking-tight text-primaryYellow">
                Send Email
              </h2>
              <p className="text-base md:text-lg font-semibold">
                <a href="mailto:info@bpsanalytics.in">info@bpsanalytics.in</a>
              </p>
            </div>
          </div>
          <div className="flex flex-row space-x-3 py-2">
            <img
              src={Icons.YellowRectangle}
              alt="YellowRectangle"
              className="h-8 w-8 xss:h-12 xss:w-12"
            />
            <div className="flex flex-col text-start">
              <h2 className="text-sm font-normal tracking-tight text-primaryYellow">
                Address
              </h2>
              <p className="capitalize text-base md:text-lg font-semibold tracking-tight text-black">
                Tower B, 3rd floor, Balaji Estate, 8 Guru Ravidas Marg,
                <br />
                Kalkaji, New Delhi - 110019
              </p>
            </div>
          </div>
          <div className="flex flex-row space-x-3 py-2">
            <img
              src={Icons.YellowRectangle}
              alt="YellowRectangle"
              className="h-8 w-8 xss:h-12 xss:w-12"
            />
            <div className="text-start">
              <h2 className="text-sm font-normal tracking-tight text-primaryYellow">
                Working Hours
              </h2>
              <p className="text-base md:text-lg capitalize font-semibold tracking-tight text-black">
                MONDAY TO FRIDAY (09:00 - 20:00 IST)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
