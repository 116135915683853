

import React from "react";
import { Icons } from "../../assets/constant";

const Growth = () => {
  return (
    <div className="flex flex-col md:flex-row m-4 md:m-12 gap-5">
      <div className="w-full md:w-1/2">
        <h1 className="text-lg md:text-2xl font-bold pb-3">
          Our Growth Journey
        </h1>
        <p className="text-sm lg:text-xl">
          Since its inception, BPS has steadily built a reputation for
          excellence. Our dedication to client success has resulted in an
          ever-growing portfolio of distinguished clients across the globe.
          We've also attracted top-tier talent and strategically broadened our
          geographic reach through acquisitions. The journey continues, with new
          opportunities for expansion, innovation, and enhanced service.
        </p>
      </div>

      <div className="w-full md:w-1/2">
        <img
          src={Icons.A8}
          alt="Growth Icon"
          className="rounded-md w-[620px] h-[300px]"
          loading="lazy "
        />
      </div>
    </div>
  );
};

export default Growth;
