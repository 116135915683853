import React from "react";

const ISO27001Certification = () => {
  return (
    <div
      className="mt-28"
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: 1.6,
        padding: "20px",
      }}
    >
      <h1
        className="text-2xl font-bold"
        style={{ fontWeight: "bold", marginBottom: "20px" }}
      >
        ISO/IEC 27001:2022 Certified: Your Data, Our Priority
      </h1>
      <p>
        At BPS, we are proud to be an <strong>ISO/IEC 27001:2022</strong>{" "}
        certified organization, demonstrating our commitment to the highest
        standards in information security management. ISO/IEC 27001 is an
        internationally recognized framework that helps organizations protect
        valuable information assets and manage data security effectively and
        cost-efficiently.
      </p>
      <p>
        This certification ensures that BPS has established, maintained, and
        continuously improved a robust Information Security Management System
        (ISMS). It’s our promise to you that your sensitive data is handled with
        the utmost security, confidentiality, and integrity.
      </p>
      <h2 className="text-lg" style={{ marginTop: "30px" }}>
        Why ISO/IEC 27001 Matters?
      </h2>
      <ul>
        <li>
          Provides a globally recognized framework for managing information
          security risks
        </li>
        <li>
          Assures clients that we are committed to safeguarding their data
        </li>
        <li>
          Enhances customer trust by meeting international security standards
        </li>
        <li>
          Protects both client and organizational data from cyber threats and
          breaches
        </li>
      </ul>
      <h3 className="text-xl" style={{ marginTop: "30px", fontWeight: "bold" }}>
        Trust BPS: Where Information Security Meets Excellence
      </h3>
      <p>
        We are dedicated to delivering secure, efficient, and reliable
        solutions, ensuring that all your data remains safe in our hands.
      </p>
    </div>
  );
};

export default ISO27001Certification;
